import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4f5d66',
        secondary: '#ee2a23',
        accent: '#ee2a23',
      },
      dark: {
        primary: '#4f5d66',
        secondary: '#ee2a23',
        accent: '#ee2a23',
      },
    },
  },
})

export default vuetify
