export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll(siteKey) {
        return this.apiService.get(`/${siteKey}/suspension`)
    }

    createOne(siteKey, suspensionData) {
        return this.apiService.post(`/${siteKey}/suspension`, suspensionData)
    }

    editOne(siteKey, suspensionData) {
        return this.apiService.put(`/${siteKey}/suspension`, suspensionData)
    }

    deleteOne(siteKey, suspensionId) {
        return this.apiService.delete(`/${siteKey}/suspension/${suspensionId}`)
    }
}