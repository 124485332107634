export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAllWings(siteKey) {
        return this.apiService.get(`/${siteKey}/zone/wings`)
    }

    getAllZones(siteKey){
        return this.apiService.get(`/${siteKey}/zone`)
    }

}
