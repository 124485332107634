export default class {
    constructor() {
        this.permissions = []
    }

    set(permissions) {
        this.permissions = permissions
    }

    can(user, ability) {
        if (!ability) return true
        if (user.role.includes('super')) return true
        return this.permissions?.some(p => p.object.toUpperCase() == ability.object.toUpperCase() && p.action.toUpperCase() == ability.action.toUpperCase() && p.set)
    }

    any(user) {
        return this.permissions?.length > 0 || user.role.includes('super')
    }
}
