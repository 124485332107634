export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getPointsSummary(siteKey, type){
        return this.apiService.get(`/${siteKey}/transaction/pointsSummary/BarChart/${type}`)
    }

    getPointsSummaryPieChart(siteKey, type, input, dataIndex, monthYear=null){
        return this.apiService.get(`/${siteKey}/transaction/pointsSummary/PieChart/${type}/${input}/${dataIndex}/${monthYear}`)
    }

    getStaffPointsSummary(siteKey, type){
        return this.apiService.get(`/${siteKey}/transaction/staffPointsSummary/BarChart/${type}`)
    }

    getStaffPointsSummaryPieChart(siteKey, type, input, dataIndex, monthYear=null){
        return this.apiService.get(`/${siteKey}/transaction/staffPointsSummary/PieChart/${type}/${input}/${dataIndex}/${monthYear}`)
    }

    getSuspensionSummary(siteKey, type){
        return this.apiService.get(`/${siteKey}/transaction/suspensionSummary/BarChart/${type}`)
    }

    getSuspensionSummaryPieChart(siteKey, type, input, suspension, monthYear=null){
        return this.apiService.get(`/${siteKey}/transaction/suspensionSummary/PieChart/${type}/${input}/${suspension}/${monthYear}`)
    }

    getPtCSummary(siteKey, type){
        return this.apiService.get(`/${siteKey}/transaction/ptcSummary/BarChart/${type}`)
    }

    getPtCSummaryPieChart(siteKey, type, input, suspension, monthYear=null){
        return this.apiService.get(`/${siteKey}/transaction/ptcSummary/PieChart/${type}/${input}/${suspension}/${monthYear}`)
    }

    getAll(siteKey) {
        return this.apiService.get(`/${siteKey}/transaction`)
    }

    getOne(siteKey, accountId) {
        return this.apiService.get(`/${siteKey}/transaction/${accountId}`)
    }

    getOneStaff(siteKey, accountId){
        return this.apiService.get(`/${siteKey}/transaction/staff/${accountId}`)
    }

    emailPDFForm(siteKey, data){
        return this.apiService.post(`/${siteKey}/transaction/forwardPDFAddress`, data)
    }

    createOne(siteKey, transactionData) {
        return this.apiService.post(`/${siteKey}/transaction/award`, transactionData)
    }

    updateOne(siteKey, transactionData) {
        return this.apiService.put(`/${siteKey}/transaction/`, transactionData)
    }
    refundOne(siteKey, transactionData) {
        return this.apiService.post(`/${siteKey}/transaction/refund`, transactionData)
    }
    refundOneStaff(siteKey, transactionData){
        return this.apiService.post(`/${siteKey}/transaction/refundStaffOne`, transactionData)
    }
    refundBatch(siteKey, transactionData){
        return this.apiService.post(`/${siteKey}/transaction/refundBatch`, transactionData)
    }
    refundStaffBatch(siteKey, transactionData){
        return this.apiService.post(`/${siteKey}/transaction/refundStaffBatch`, transactionData)
    }

    deleteOne(siteKey, transactionId) {
      return this.apiService.delete(`/${siteKey}/transaction/${transactionId}`)
    }

    getTransactionsByDateRange(siteKey, startDate, endDate, data) {
        return this.apiService.post(`/${siteKey}/transaction/${startDate}/${endDate}`, data)
    }

    getTransactionsNoReceipt(siteKey, accountId){
        return this.apiService.get(`/${siteKey}/transaction/getTransactionsNoReceipt/${accountId}`)
    }

    getTransactionsNoReceiptStaff(siteKey, accountId){
        return this.apiService.get(`/${siteKey}/transaction/getTransactionsNoReceiptStaff/${accountId}`)
    }
}
