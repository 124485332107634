<template>
  <v-container fluid>   
   <v-data-table fixed-header :headers="tableHeaders" :search="search" :items="data" dense
      class="px-6 py-6 elevation-2 flex-grow" item-key="incentiveID" :expanded.sync="expanded"
      @click:row="showIncentiveChild" :loading="loading" loading-text="Loading... Please wait">

      <template v-slot:top>
        <v-toolbar class="mb-4" flat>
          <v-toolbar-title class="text-h4">Incentives</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
            hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2 mr-2" large @click="reorderItems">Reorder</v-btn>
          <v-btn color="primary" dark class="mb-2" large @click="createItem">Add Incentive</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.hasChildren`]="{ item }">
        <v-chip v-if="item.hasChildren == true" color="black" small class="white--text">Nested
          <v-icon>mdi-chevron-down</v-icon></v-chip>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td class="px-0 py-0" :colspan="headers.length">
          <v-data-table hide-default-footer :items="findChildIncentive(item)" :headers="tableHeaders" disable-pagination>
            <tr v-for="(child, index) in findChildIncentive(item)" :key="index">
              <td>{{ child }}</td>
            </tr>
            <template v-slot:[`item.hasChildren`]>
              <v-chip small color="black" class="white--text">Child</v-chip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-chip small text-color="white" class="ma-0"
                :color="item.active == true ? 'green' : '' || item.active == false ? 'red' : ''">
                {{ item.active == true ? 'Active' : '' || item.active == false ? 'Disabled' : '' }}
              </v-chip>
            </template>>
            <template v-slot:[`item.time`]="{ item }">
              {{ newTimeslice(item) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn x-small fab class="mr-2 primary white--text"
                @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              <!-- <v-icon medium color="red darken-2" @click="deleteItem(item)">mdi-delete</v-icon> -->
            </template>
          </v-data-table>
        </td>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-chip small text-color="white" class="ma-0"
          :color="item.active == true ? 'green' : '' || item.active == false ? 'red' : ''">
          {{ item.active == true ? 'Active' : '' || item.active == false ? 'Disabled' : '' }}
        </v-chip>
      </template>

      <template v-slot:[`item.parcel`]="{ item }">
        <v-chip small text-color="white" class="ma-0"
          :color="item.parcel == true ? 'green' : 'cyan' || item.parcel == null ? 'cyan' : ''">
          {{ item.parcel == true ? 'Parcel' : 'Generic' || item.parcel == null ? 'Generic' : '' }}
        </v-chip>
      </template>

      <template v-slot:[`item.parcelOptions`]="{ item }">
        <div v-if="item.parcelOptions">
          <v-chip v-for="(data, index) in item.parcelOptions.split('\n')" :key="index" small text-color="white" class="ma-0" color="grey">
            {{ data }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.time`]="{ item }">
        {{ newTimeslice(item) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn x-small fab class="mr-2 primary white--text" @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
        <!-- <v-icon medium color="red darken-2" @click="deleteItem(item)">mdi-delete</v-icon> -->
      </template>
    </v-data-table>

    <v-dialog persistent v-model="dialog" width="900px">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          {{ formTitle }}
          <v-icon large class="white--text ml-4">mdi-trophy</v-icon>
        </v-card-title>
        <v-container class="px-8">
          <v-form ref="form" v-model="formValid">
            <v-row>
              <v-col class="py-2 text-center d-inline-block" cols="12">
                <img v-if="imagePath" width="300" :src="imageData">
              </v-col>

              <v-col class="py-2" cols="10">
                <v-text-field v-model="editedItem.title" label="Title" :rules="inputRules" required>
                  <v-icon slot="prepend">mdi-format-text</v-icon>
                </v-text-field>
              </v-col>

              <v-col class="py-2" cols="2">
                <label>Status</label>
                <v-switch v-model="editedItem.active" :label="editedItem.active ? 'Active' : 'Disabled'" color="success"
                  class="mt-1" hide-details></v-switch>
                <v-switch v-model="editedItem.parcel" :label="editedItem.parcel ? 'Parcel' : 'Generic'" color="success"
                  class="mt-1" hide-details></v-switch>
              </v-col>

              <v-col class="py-2" cols="12">
                <v-textarea v-model="editedItem.description" auto-grow rows="1" label="Description" :rules="inputRules">
                  <v-icon slot="prepend">mdi-text</v-icon>
                </v-textarea>
              </v-col>

              <v-col class="py-2" cols="2">
                <v-text-field v-model="editedItem.cost" label="Cost (points)" type="number" :rules="inputRules">
                  <v-icon slot="prepend">mdi-star</v-icon>
                </v-text-field>
              </v-col>

              <v-col class="py-2" cols="2">
                <v-text-field v-model="editedItem.price" label="Price (£)" type="number" :rules="inputRules">
                  <v-icon slot="prepend">mdi-currency-gbp</v-icon>
                </v-text-field>
              </v-col>

              <v-col class="py-2 text-center" cols="4">
                <v-file-input v-model="imagePath" label="File input"></v-file-input>
                <v-btn v-if="imagePath" class="d-inline-flex justify-center" @click="importImage">Preview</v-btn>
              </v-col>

              <v-col class="py-2" cols="4">
                <v-select :items="incentive" v-model="editedItem.parentIncentiveID" item-text="title" x
                  item-value="incentiveID" clearable label="Parent incentive (optional)">
                  <v-icon slot="prepend">mdi-arrow-left-right</v-icon>
                </v-select>
              </v-col>


              <v-col cols="4" class="w-100 pb-0 d-flex justify-center text-center">
                <v-radio-group col class="py-0 mt-0 flex-grow-1 text-center justify-center d-block"
                  v-model="editedItem.frequency">
                  <v-radio label="One-time purchase" true-value="-1" :value="-1"></v-radio>
                  <v-radio label="Unlimited purchase" true-value="0" :value="0"></v-radio>
                  <v-radio label="Choose timeframe" :value="editedItem.frequency > 0 ? editedItem.frequency : 1"
                    true-value="1"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col 
                cols="4" class="w-100 pb-0 d-flex justify-center text-center"
                :style="editedItem.parcel?'visibility:visible':'visibility:hidden'"
              >
                <v-textarea v-model="editedItem.parcelOptions" label="Enter list of Parcel Items here" />
              </v-col>

              <v-col 
                cols="4" class="w-100 pb-0 d-flex justify-center text-center"
                :style="editedItem.parcel?'visibility:visible':'visibility:hidden'"
              >
                <v-text-field 
                  min="0" v-model="editedItem.parcelOptionCount" label="No. of purchasable items per account"
                  type="number"
                />
              </v-col>



              <v-col cols="8" class="pt-0" v-if="editedItem.frequency > 0 || editedItem.frequency === ''">
                <v-row class="mt-0">
                  <v-col class="py-2" cols="3" sm="3" md="3">
                    <v-text-field v-model="editedItem.purchase" type="number" min="1" label="Purchase" number>
                      <v-icon slot="prepend">mdi-counter</v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-2" cols="3" sm="3" md="3">
                    <v-text-field v-model="editedItem.frequency" type="number" min="1" label="Frequency" number>
                      <v-icon slot="prepend">mdi-counter</v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-2" cols="12" sm="12" md="6">
                    <v-select :items="timesliceItems" v-model="editedItem.timeslice" item-text="text" item-value="value"
                      required label="Timeslice">
                      <v-icon slot="prepend">mdi-calendar-clock</v-icon>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="text-center my-2" v-if="editedItem.frequency > 0 && editedItem.timeslice != null">
              <b>Available to purchase:</b> 
              {{newTimeslice(editedItem)}}
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text large @click="close">Cancel</v-btn>
              <v-btn class="primary" large @click="save">Save</v-btn>
            </v-card-actions>

          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReorder" width="unset">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          Reorder Incentives
          <v-icon large class="white--text ml-4">mdi-trophy</v-icon>
        </v-card-title>
        <v-container style="max-height: 180px;overflow: auto; align-items: center; justify-content: center; display: flex;">
          <draggable v-model="data" @start="drag=true" @end="drag=false, dragEnd()" style="margin-top:25%">
            <tr v-for="(item, index) in data" :key="index">
              <v-icon small class="page__grab-icon mr-5">
                mdi-arrow-all
              </v-icon>
              <td>{{item.title}}</td>
            </tr>
          </draggable>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogReorder=false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn background="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Draggable from 'vuedraggable';
export default {
  components:{
    Draggable,
  },
  data() {
    return {
      data: [],
      incentive: [],
      loading: true,
      imageData: '',
      imagePath: '',
      expanded: [],
      singleExpand: false,
      tableHeaders: [
        { text: 'Title', cellClass: "py-2", value: 'title', width: "150px" },
        { text: 'Description', cellClass: "py-2", value: 'description', width: "350px" },
        { text: 'Cost (points)', cellClass: "py-2", value: 'cost', width: "120px", align: "end" },
        { text: 'Price (£)', cellClass: "py-2", value: 'price', width: "120px", align: "end" },
        { text: 'Time', cellClass: "py-2", value: 'time', width: "120px" },
        // { text: 'Parent Incentive', cellClass: "py-2", value: 'parentIncentiveID', width: "160px" },
        { text: 'Active', cellClass: "py-2", value: 'active', width: "100px", align: "center" },
        { text: 'Parcel', cellClass: "py-2", value: 'parcel', width: "100px", align: "center" },
        { text: 'Parcel Options', cellClass: "py-2", value: 'parcelOptions', width: "100px", align: "center" },
        { text: 'Children', value: 'hasChildren', align: "center", width: "120px" },
        { text: 'Action', cellClass: "py-2", value: 'actions', sortable: false, align: "end", width: "70px" },
      ],
      timesliceItems: [
        { text: "Day", value: "D" },
        { text: "Month", value: "M" },
        { text: "Week", value: "W" },
        { text: "Quarter", value: "Q" },
        { text: "Year", value: "Y" }
      ],
      editedItem: {
        array: null,
        fileType: null,
        purchaseAvailable: false,
        nextPurchaseIndicator: null,
        description: "",
        cost: null,
        price: null,
        timeslice: null,
        frequency: null,
        title: "",
        active: false,
        linkGroup: null,
        staffIncentive: true,
        parcel: false,
        parcelOptionCount: 0,
        parcelOptions: null,
      },
      editedIndex: -1,
      customFrequency: null,
      selectedModalTab: null,
      dialog: false,
      dialogDelete: false,
      dialogReorder: false,
      search: "",
      formValid: false,
      inputRules: [
        v => !!v || 'Field is required'
      ],
      snackbarText: '',
      snackbarVisible: false,
      snackbarColor: ''
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Incentive Item' : 'Edit Incentive Item'
    },
    activeHeaders() {
      return this.headers.filter((h) => {
        if (!this.allowDrag && h.value === "lock") {
          return false;
        }
        return true;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  async mounted() {
    await this.retrieveIncentives()
  },
  methods: {
    dragEnd(){
      this.data.forEach((element)=>{
        element.selfOrder = this.data.indexOf(element);
        this.$api.incentive.updateOne(this.$siteKey, element)
      })
    },
    async retrieveIncentives() {
      try {
        var response = await this.$api.incentive.getAllStaff(this.$siteKey)
        this.incentive = response.data;
        this.itemsWithoutParent();
        this.loading = false
        this.snackbarText = 'Incentives loaded.'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (err) {
        this.snackbarText = 'Failed to load Incentives.'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(err)
      }
    },
    reset() {
      this.editedItem = {
        array: null,
        fileType: null,
        purchaseAvailable: false,
        nextPurchaseIndicator: null,
        description: "",
        cost: null,
        price: null,
        timeslice: null,
        frequency: null,
        title: "",
        active: false,
        linkGroup: null,
        imagePath: ""
      }
    },
    reorderItems(){
      this.dialogReorder = true;
      this.reset();
    },
    createItem() {
      this.dialog = true
      this.reset()
    },
    editItem(item) {
      this.editedIndex = this.incentive.indexOf(item)
      this.editedItem = Object.assign({}, item)

      // If this incentive has an image, display the image
      if (this.editedItem.array != null && this.editedItem.imagePath != null) {
        this.imagePath = this.editedItem.imagePath
        this.imageData = `data:image/${this.editedItem.fileType};base64,` + this.editedItem.array
      }

      this.dialog = true
    },
    newTimeslice(item){
      if (item.frequency > 0 && item.timeslice != null) {
        var constructionString = `${item.purchase == 0 ? 1:item.purchase} time(s) every`;

        switch (item.timeslice) {
          case "D":
            if (item.frequency != 0) {
              constructionString = `${constructionString} ${item.frequency} day(s)`
            } 
            break;
          case "W":
            if (item.frequency != 0) {
              constructionString = `${constructionString} ${item.frequency} week(s)`
            } 
            break;
          case "M":
            if (item.frequency != 0) {
              constructionString = `${constructionString} ${item.frequency} month(s)`
            }
            break;
          case "Q":
            if (item.frequency != 0) {
              constructionString = `${constructionString} ${item.frequency} quarter(s)`
            }
            break;
          case "Y":
            if (item.frequency != 0) {
              constructionString = `${constructionString} ${item.frequency} year(s)`
            } 
            break;
        }
        return constructionString
      } else {
        switch (item.frequency) {
          case -1:
            return "One-time purchase"
          case 0:
            return "Unlimited"
        }
      }
    },
    deleteItem(item) {
      this.editedIndex = this.incentive.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      try {
        await this.$api.incentive.deleteOne(this.$siteKey, this.editedItem.incentiveID)
        this.incentive.splice(this.editedIndex, 1)
        this.closeDelete()
      } catch (error) {
        console.log(error)
      }
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
        this.retrieveIncentives()
        this.imagePath = ""
        this.imageData = ""
      })
      this.dialog = false
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
        this.imagePath = ""
        this.imageData = ""
      })
    },
    next() {
      this.$nextTick(() => {
        this.editedIndex = -1
        this.retrieveIncentives()
      })
      this.dialog = false
    },
    async saveImage() {
      alert('image saved')
    },
    async save() {
      this.editedItem.frequency = parseInt(this.editedItem.frequency)
      if (!this.editedItem.active) {
        this.editedItem.active = false
      }
      let editedItem = this.editedItem
      editedItem.selfOrder = this.data.length+1;
      let _self = this
      if (this.editedIndex > -1) {
        try {
          editedItem.staffIncentive = true;
          await this.$api.incentive.updateOne(this.$siteKey, editedItem)
          this.snackbarText = 'Incentive updated'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          if (_self.imagePath != null && _self.imagePath != '') {
            await this.uploadImage()
          }
          this.close()
        } catch (error) {
          this.snackbarText = 'Failed to update incentive'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(error)
        }
      } else {
        try {
          editedItem.staffIncentive = true;
          await this.$api.incentive.createOne(this.$siteKey, editedItem)
          this.snackbarText = 'Incentive added'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          if (_self.imagePath != null && _self.imagePath != '') {
            await this.uploadImage()
          }
          this.close()
        } catch (error) {
          this.snackbarText = 'Failed to add incentive'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(error)
        }
      }
      await this.retrieveIncentives();
    },
    importImage() {
      var reader = new FileReader();
      reader.readAsDataURL(this.imagePath)
      reader.onload = () => {
        this.imageData = reader.result;
        // this.editedItem.imagePath = this.imageData
      }
    },
    async uploadImage() {
      let image = this.imagePath
      let formData = new FormData();
      formData.append('image', image);
      let incentiveID = this.editedItem.incentiveID
      try {
        await this.$api.incentive.addImage(this.$siteKey, incentiveID, formData)
        this.snackbarText = 'Incentive image updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update incentive image'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    findChildIncentive(item) {
      let foundChildren = this.incentive.filter(incentive => incentive.parentIncentiveID == item.incentiveID)
      if (foundChildren.length > 0) {
        return foundChildren
      }
    },
    itemsWithoutParent() {
      this.data = this.incentive.filter(incentive => incentive.parentIncentiveID == null)
      console.log(this.data)
    },
    showIncentiveChild(item, event) {
      if (item.hasChildren == true) {
        if (event.isExpanded) {
          const indexExpanded = this.expanded.findIndex(i => i === item);
          this.expanded.splice(indexExpanded, 1)
        } else {
          this.expanded.push(item);
        }
      }
    }
  }
}
</script>

<style>
.v-data-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
