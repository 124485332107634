export default class {
    constructor(pdfService) {
        this.pdfService = pdfService
    }

    getAll(sitekey) {
        return this.pdfService.get(`/v1.1/${sitekey}/cris/PDFBuilder`)
    }

    previewPDF(sitekey, pdfFile){
        return this.pdfService.get(`/v1.1/${sitekey}/cris/PDFBuilder/preview/${pdfFile}`)
    }

    uploadPDF(sitekey, data){
        return this.pdfService.post(`/v1.1/${sitekey}/cris/PDFBuilder/uploadTemplate`, data)
    }

    reUploadPDF(sitekey, data){
        return this.pdfService.post(`/v1.1/${sitekey}/cris/PDFBuilder/reUploadTemplate`, data)
    }

    getPDFTypes(sitekey, pdfFile){
        return this.pdfService.get(`/v1.1/${sitekey}/cris/PDFBuilder/template/${pdfFile}/getPDFTypes`)
    }

    createFinishedPDF(sitekey, pdfFile, data){
        return this.pdfService.post(`/v1.1/${sitekey}/cris/PDFBuilder/template/${pdfFile}/create`, data)
    }
}