export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll(siteKey) {
        return this.apiService.get(`/${siteKey}/incentive`)
    }

    getAllStaff(siteKey) {
        return this.apiService.get(`/${siteKey}/incentive/getStaff`)
    }

    createOne(siteKey, incentiveData) {
        return this.apiService.post(`/${siteKey}/incentive`, incentiveData)
    }

    updateOne(siteKey, incentiveItem) {
        return this.apiService.put(`/${siteKey}/incentive/`, incentiveItem)
    }

    addImage(siteKey, incentiveId,formData) {
        return this.apiService.post(`/${siteKey}/incentive/${incentiveId}/image`, formData)
    }

    deleteOne(siteKey, incentiveId) {
      return this.apiService.delete(`/${siteKey}/incentive/${incentiveId}`)
    }

    getAllIncentiveRules(siteKey, incentiveID){
        return this.apiService.get(`/${siteKey}/incentive/getAllIncentiveRules/${incentiveID}`)
    }

    editIncentiveRule(siteKey, data){
        return this.apiService.post(`/${siteKey}/incentive/editIncentiveRule`, data)
    }

    deleteIncentiveRule(siteKey, ruleID){
        return this.apiService.delete(`/${siteKey}/incentive/deleteIncentiveRule/${ruleID}`)
    }
}
