export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getOne(siteKey) {
        return this.apiService.get(`/${siteKey}/site`)
    }

    addGuide(siteKey,formData) {
        return this.apiService.post(`/${siteKey}/site/guide`, formData)
    }

    addBooklet(siteKey,formData) {
        return this.apiService.post(`/${siteKey}/site/booklet`, formData)
    }

    addTcGuide(siteKey,formData) {
        return this.apiService.post(`/${siteKey}/site/tcguide`, formData)
    }

    addTcBooklet(siteKey,formData) {
        return this.apiService.post(`/${siteKey}/site/tcbooklet`, formData)
    }

    addStaffGuide(siteKey,formData) {
        return this.apiService.post(`/${siteKey}/site/staffGuide`, formData)
    }
    addStaffBooklet(siteKey,formData) {
        return this.apiService.post(`/${siteKey}/site/staffBooklet`, formData)
    }

    setReceiptFileName(siteKey, site) {
        return this.apiService.post(`/${siteKey}/site/receiptFile`, site)
    }

    uploadNoticeboard(siteKey, userType, data) {
        return this.apiService.post(`/${siteKey}/site/uploadNoticeboard/${userType}`, data)
    }

    getAllNoticeboard(siteKey){
        return this.apiService.get(`/${siteKey}/site/getAllNoticeboard`)
    }
}
