<template>
    <div>
        <router-view/>
    </div>
</template>
<script>
    export default {
        // eslint-disable-next-line
        name: 'Container',
    }
</script>