export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll(siteKey) {
        return this.apiService.get(`/${siteKey}/members`)
    }

    createEditMember(siteKey, data){
        return this.apiService.post(`/${siteKey}/members/createEditMember`, data)
    }

    deleteMember(siteKey, id){
        return this.apiService.delete(`/${siteKey}/members/${id}`)
    }
}