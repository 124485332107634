<template>
    <v-container fluid style="height: 100%">
      <div v-if="showLoading" class="text-center" style="height:100%">
        <v-row class="fill-height" justify="center" align="center">
          <v-col cols="12">
            <v-progress-circular indeterminate color="black"></v-progress-circular>
            <p class="py-4">Loading Information, Please Wait...</p>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <PointsSummary/>

        <v-row class="my-2">
          <v-col cols="12" md="12" sm="12"></v-col>
        </v-row>

        <StaffPointsSummary/>

        <v-row class="my-2">
          <v-col cols="12" md="12" sm="12"></v-col>
        </v-row>

        <SuspensionsSummary/>

        <v-row class="my-2">
          <v-col cols="12" md="12" sm="12"></v-col>
        </v-row>

        <PointsToCreditSummary/>

        
      </div>
      
      <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn 
            dark 
            text 
            v-bind="attrs" 
            @click="snackbarVisible = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
</template>

<script>
  import PointsSummary from '../../components/PointsSummary'
  import SuspensionsSummary from '../../components/SuspensionsSummary'
  import PointsToCreditSummary from '../../components/PointsToCreditSummary'
  import StaffPointsSummary from '../../components/StaffPointsSummary'

  export default {
    // eslint-disable-next-line
    name: 'Dashboard',
    data() {
      return {
        snackbarVisible: false,
        showLoading: true,
        suspensionReasons: [],
      }
    },
    components: {
      PointsSummary,
      SuspensionsSummary,
      PointsToCreditSummary,
      StaffPointsSummary
    },
    methods: {
      async fetchTransactions() {
        try {
            this.showLoading = false
        } catch (err) {
            this.snackbarText = 'Unable to load transactions.'
            this.snackbarColor = 'red'
            this.snackbarVisible = true
            console.log(err)
        }
      }
    },
    created() {
      this.fetchTransactions()
    },
  }
</script>
