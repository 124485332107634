<template>
  <v-container fluid>
    <v-data-table
      :headers="tableAccountHeaders"
      :items="accounts"
      :search="search"
      class="elevation-1 px-6 py-6"
      :loading="loadingTable"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar class="mb-4" flat>
          <v-toolbar-title class="text-h4"
            >Accounts
            <span v-if="accounts.length > 0"
              >({{ accounts.length }})</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          
          <div class="d-flex justify-center align-center">
            <div class="d-inline-block text-center mt-6 mr-10 align-center">
              <input ref="fileUpload" type="file" hidden accept=".csv" @change="handleFileUpload"/>
              <v-btn class="primary" @click="chooseFiles()" v-if="!importProcessing">Import Accounts</v-btn>
              <v-progress-circular
                v-else
                :size="50"
                :width="7"
                color="impbblue"
                indeterminate
              ></v-progress-circular>

            </div>
          </div>
          
          <div class="d-flex justify-center align-center">
            <div class="d-inline-block text-center mt-6 mr-10 align-center">
              <v-btn class="primary" @click="reset(), dialog=true">Create Account</v-btn>
            </div>
          </div>
          <div class="d-inline-block text-center mt-6 mr-10 align-center">
            <v-btn class="primary" @click="getExportData" v-if="!exportProcessing">Export</v-btn>
            <v-progress-circular
              v-else
              :size="50"
              :width="7"
              color="impbblue"
              indeterminate
            ></v-progress-circular>

        </div>
        </v-toolbar>
      </template>
      <template v-slot:[`item.suspensionEndDate`]="{ item }">
        {{
          item.suspensionEndDate != null
            ? timestampFormat(item.suspensionEndDate)
            : ""
        }}
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          v-model="item.active"
          :color="item.active?'success':'danger'"
          dense
          disabled
          :value="item.active"
        ></v-switch>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          text-color="white"
          :color="pointsActiveColour(item)"
        >
          {{item.active? statusList[item.status]:'Disabled'}}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium color="primary" class="mr-2" @click="newAccount=false, activeTab=0, viewAccount(item), fetchAccountTransactions()"
          >mdi-account-eye</v-icon
        >
        <v-icon medium color="primary" class="mr-2" @click="$router.push({name: 'PointsStaff', params: { 'autoSelectAccount': item}})"
          >mdi-trophy</v-icon
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-if="snackbarVisible === true"
      v-model="snackbarVisible"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card>
        <v-card-title
          class="
            mb-2
            justify-start
            px-4
            py-6
            primary
            white--text
            text-h4
            justify-center
          "
        >
          <span v-if="!newAccount">
            Account:
            {{ editedAccount.name + " - " + editedAccount.urn }}
          </span>
          <span v-else>
            Create New Account
          </span>

          
        </v-card-title>
        <v-container class="px-8">
          <v-tabs v-model="activeTab">
            <v-tab>
              <v-icon class="text--black" left> mdi-account-view </v-icon>
              Account Details
            </v-tab>
            <v-tab v-if="!newAccount">
              <v-icon class="text--black" left> mdi-accounts </v-icon>
              Transactions
            </v-tab>

            <v-tab-item class="mt-6">
              <v-card flat>
                <v-row>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.name"
                      filled
                      label="Name"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong>Name</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.email"
                      filled
                      label="Email"
                      :suffix="emailSuffix"
                      :append-icon="$userRole=='SuperUser'&&emailSuffix?'mdi-close':''"
                      @click:append="emailSuffix=null"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong>Email</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-switch 
                      v-model="editedAccount.importDisable" 
                      :label="editedAccount.importDisable?'Active if not found':'Disable if not found'"
                    >
                    </v-switch>
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.urn"
                      filled
                      label="URN"
                      >
                      <template #label>
                        <span class="red--text"><strong>* </strong>URN</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.balance"
                      readonly
                      filled
                      label="Balance"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-menu
                      ref="datePicker"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :return-value.sync="datePicker"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computeLeavingDate"
                          label="Leaving Date"
                          prepend-icon="mdi-calendar"
                          clearable
                          @click:clear="nullifyLeaving = true"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedAccount.leavingDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="datePicker = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="datePicker = false"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="4" class="pt-2">
                    <div class="text-center d-flex align-center mb-4">
                      <label class="ma-0 mr-2">Account Status:</label>
                      <v-chip
                        small
                        text-color="white"
                        class="ma-0"
                        :color="pointsActiveColour(editedAccount)"
                      >
                        {{editedAccount.active ? statusList[editedAccount.status] : 'Disabled'}}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="py-2" v-for="(item, index) in editedAccount.properties" :key="index">
                    <v-text-field
                      v-if="index!='continuousServiceDate'"
                      v-model="editedAccount.properties[index]"
                      filled
                      :readonly="index=='groupHireDate'"
                      :clearable="index!='groupHireDate'"
                     
                    >
                      <template #label>
                        <span>{{index.replace(/([a-z])([A-Z])/g, '$1 $2').charAt(0).toUpperCase() + index.replace(/([a-z])([A-Z])/g, '$1 $2').slice(1)}}</span>
                      </template>
                    </v-text-field>

                    <v-menu
                      v-else
                      ref="serviceDatePicker"
                      v-model="serviceDatePicker"
                      :close-on-content-click="false"
                      :return-value.sync="datePicker"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computeServiceDate"
                          label="Continuous Service Date"
                          clearable
                          filled
                          @click:clear="nullifyContinuous=true"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedAccount.properties.continuousServiceDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="serviceDatePicker = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="serviceDatePicker = false"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>

                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>

            <v-tab-item class="mt-6">
              <v-card flat>
                <!--DataTable here-->
                <v-data-table
                  :headers="tableTransactionHeaders"
                  :items="accountTransactions"
                >
                  <template v-slot:[`item.active`]="{ item }">
                    <v-simple-checkbox
                      color="primary"
                      v-model="item.active"
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      v-if="item.transactionMonetaryValue > 0"
                      small
                      class="ma-2"
                      text-color="white"
                      :color="
                        item.status == 0
                          ? 'green'
                          : '' || item.status == 1
                          ? 'orange'
                          : '' || item.status == 2
                          ? 'red'
                          : '' 
                      "
                    >
                      {{ item.status == true ? "Reviewed" : "Not Reviewed" }}
                      {{
                        item.status == 0
                          ? "Complete"
                          : "" || item.status == 1
                          ? "Pending"
                          : "" || item.status == 2
                          ? "Declined"
                          : ""
                      }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-card-actions class="mt-8">
            <v-spacer></v-spacer>
            <v-btn color="primary" large @click="dialog = false">Close</v-btn>
            <v-btn 
              color="primary" 
              large 
              @click="dialog = false, saveAccount()"
              :disabled="editedAccount.name==null || editedAccount.urn==null || editedAccount.email==null"
            >
              {{newAccount?'Create':'Save'}}
            </v-btn>
            <v-btn 
              class="accent" 
              large 
              v-if="editedAccount.staffAccountID!=null" 
              @click="editedAccount.active ? editedAccount.active = false:editedAccount.active=true"
            >
              {{editedAccount.active ? 'Disable':'Enable'}} Account
            </v-btn>
            <v-btn v-if="!newAccount" class="accent" large @click="updateAccountSuspension()">
              {{editedAccount.status == 1 ? "Suspend Account" : "Unsuspend Account"}}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Papa from 'papaparse'
export default {
  data() {
    return {
      csvFile: null,
      activeTab: 0,
      newAccount: false,
      statusList: ["Suspended", "Active", "Withdrawn"],
      accounts: [],
      accountTransactions: [],
      suspensions: [],
      loadingTable: true,
      datePicker: false,
      serviceDatePicker: false,
      tableAccountHeaders: [
        // {text: "Account ID", value: "accountID"},
        { text: "Name", value: "name" },
        { text: "URN", value: "urn" },
        { text: "Email", value: "email"},
        { text: "Status", value: "status", align: "end"},
        //{ text: "Active", value: "active"},
        { text: "Balance", value: "balance", align: "end" },
        {
          text: "Action",
          cellClass: "py-2",
          value: "actions",
          sortable: false,
          align: "end",
          width: "100px",
        },
      ],
      tableTransactionHeaders: [
        { text: "Date", align: "start", sortable: true, value: "timestamp" },
        { text: "Cell", value: "accountLocation" },
        { text: "Description", value: "description" },
        { text: 'Reason', value: 'awardReason' },
        { text: "Status", value: "status", align: "end" },
        { text: "Cost (£)", value: "cost", align: "end" },
        { text: "Amount", value: "transactionAmount", align: "end" },
        { text: "Balance", value: "accountBalance", align: "end" },
      ],
      editedAccount: {
        staffAccountID: null,
        name: null,
        email: null,
        urn: null,
        balance: 0,
        siteKey: this.$siteKey,
        active: true,
        status: 1,
        importDisable: false,
      },
      snackbarVisible: false,
      dialog: false,
      suspensionDialog: false,
      extendSuspensionDialog: false,
      search: null,
      importProcessing: false,
      emailSuffix: null,
      emailDomains: {
        "ser": "@serco.com",
        "iom": "@gov.im",
        "sod": "@sodexogov.co.uk",
        "hmpps": "@justice.gov.uk"
      },

      pattern: /\b(?:Prof *|Dr *|Mr *|Miss *|Ms *|Mrs *|)\b/g,
      nullifyLeaving: false,
      nullifyContinuous: false,
      exportProcessing: false,
    };
  },
  components: {},
  async mounted() {
    await this.retrieveAccounts();
    this.calcSuffix();
  },
  watch:{
    "editedAccount.name":function(newVar, oldVar){
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!oldVar && !this.editedAccount.email || (oldVar=="" && this.editedAccount.email=="")){
        this.editedAccount.email = newVar.split(" ").join(".").toLowerCase();
      }
      else if (!this.editedAccount.email.match(emailPattern)){
        this.editedAccount.email = newVar.replace(this.pattern, "").split(" ").join(".").toLowerCase();
      }
    }
  },
  computed:{
    computeLeavingDate(){
      return this.formatDate(this.editedAccount.leavingDate);
    },
    computeServiceDate(){
      return this.formatDate(this.editedAccount.properties.continuousServiceDate);
    }
  },
  methods: {
    objectToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
          const values = headers.map(header => {
              const val = row[header]
              return `"${val}"`;
          });
          csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    },
    async getExportData(){
      this.exportProcessing = true;
      var response = await this.$api.account.getAllStaff(this.$siteKey)
      for (var i = 0; i < response.data.length; i++){
        delete response.data[i]["properties"]
        delete response.data[i]["importDisable"]
      }
      let csvContent = "data:text/csv;charset=utf-8," + this.objectToCsv(response.data);
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'CRIS_Staff_Accounts_Export_' + this.getCurrentDate());
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file.
      this.exportProcessing = false;
    },
    calcSuffix(email = null){
      if (email == null || !email.includes("@")){
        Object.keys(this.emailDomains).forEach((element)=>{
          if (this.$siteKey.includes(element)){
            this.emailSuffix = this.emailDomains[element]
          }
        })
      }
      else{
        this.emailSuffix = null;
      }
    },
    chooseFiles() {
      this.$refs.fileUpload.click()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`

    },
    download(filename, text) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    async handleFileUpload(event) {
      this.importProcessing = true
      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onload = async () => {
        var csvData = Papa.parse(reader.result)
        var staffToImport = []
        console.log(csvData)
        for (let i = 0; i < csvData.data.length ; i++){
          var emailSet = false
          if (csvData.data[i][0] == "") { continue }
          else if (csvData.data[i][0] == "Primary SAP ID") {
            for (let x = 0; x < csvData.data[i].length; x++) {
              if (csvData.data[i][x] == "Primary SAP ID") {
                var urnIndex = x
              }
              else if (csvData.data[i][x] == "Employee") {
                var nameIndex = x
              }
              else if (csvData.data[i][x] == "Cont Serv Date") {
                var contServIndex = x
              }
              else if (csvData.data[i][x] == "Group Hire Date") {
                var groupHireIndex = x
              }
              else if (csvData.data[i][x] == "Email Address (SSO)" && !emailSet) {
                var emailIndex = x
                emailSet = true
              }
              else if (csvData.data[i][x] == "Weekly Working Hours") {
                var hoursIndex = x
              }
              else if (csvData.data[i][x] == "Organisation Unit") {
                var orgUnitIndex = x
              }
              else if (csvData.data[i][x] == "Position") {
                var positionIndex = x
              }
              else if (csvData.data[i][x] == "Employee Group") {
                var groupIndex = x
              }
              else if (csvData.data[i][x] == "Employee Subgroup") {
                var subgroupIndex = x
              }
              else if (csvData.data[i][x] == "Job Band") {
                var jobIndex = x
              }
              else if (csvData.data[i][x] == "Full Time Flag") {
                var fullTimeIndex = x
              }
              else if (csvData.data[i][x] == "Username") {
                var usernameIndex = x
              }
              else if (csvData.data[i][x] == "Manager") {
                var managerIndex = x
              }
              else if (csvData.data[i][x] == "ID" && x > managerIndex) {
                var managerIdIndex = x
              }
            }
            continue
          }
          var newStaff = {
            staffAccountID: null,
            name: csvData.data[i][nameIndex].replace(this.pattern, ""),
            urn: csvData.data[i][urnIndex],
            email: csvData.data[i][emailIndex].toLowerCase(),
            siteKey: this.$siteKey,
            active: true,
            balance: 0,
            status: 1,
            importDisable: false,
            properties: {
              UserID: csvData.data[i][usernameIndex],
              OrgUnit: csvData.data[i][orgUnitIndex],
              Position: csvData.data[i][positionIndex],
              EmployeeGroup: csvData.data[i][groupIndex],
              EmployeeSubgroup:	csvData.data[i][subgroupIndex],
              FullTime:	csvData.data[i][fullTimeIndex]=="X",
              ActualHours: parseInt(csvData.data[i][hoursIndex]),
              ContinuousServiceDate: new Date(csvData.data[i][contServIndex].split('.')[2], csvData.data[i][contServIndex].split('.')[1] - 1, csvData.data[i][contServIndex].split('.')[0]),
              GroupHireDate: new Date(csvData.data[i][groupHireIndex].split('.')[2], csvData.data[i][groupHireIndex].split('.')[1] - 1, csvData.data[i][groupHireIndex].split('.')[0]),
              ManagerURN:	csvData.data[i][managerIdIndex],
              Manager: csvData.data[i][managerIndex],
              JobBand: csvData.data[i][jobIndex]
            }
          }
          staffToImport.push(newStaff)
        }
        var textString = "";
        this.accounts.forEach((dbAccount)=>{
          var found = false;
          for (var i = 0; i < staffToImport.length; i++){
            if (staffToImport[i].urn == dbAccount.urn){
              found = true;
            }
          }
          if (!found && dbAccount.active && !dbAccount.importDisable){
            dbAccount.active = false;
            this.$api.account.createStaffAccount(this.$siteKey, dbAccount);
            textString += `URN ${dbAccount.urn} ${dbAccount.name} - Account disabled\n`
          }
        })
        var response = await this.$api.account.importStaffAccounts(this.$siteKey, staffToImport);
        textString += response.data
        this.importProcessing = false
        this.download("Import Output", textString)
        this.retrieveAccounts();
      }
    },
    reset(){
      this.newAccount = true;
      this.activeTab = 0;
      this.editedAccount = {
        staffAccountID: null,
        name: null,
        email: null,
        urn: null,
        balance: 0,
        siteKey: this.$siteKey,
        active: true,
        status: 1,
        importDisable: false,
      };
      this.calcSuffix();
    },
    async saveAccount(){
      if (this.editedAccount.leavingDate && !this.nullifyLeaving){
        this.editedAccount.leavingDate = new Date(this.editedAccount.leavingDate).toISOString();
      } else{
        this.editedAccount.leavingDate = null;
      }

      if (!this.editedAccount.properties) {
        this.editedAccount.properties = {}
      }
      if (this.editedAccount.properties.continuousServiceDate && !this.nullifyContinuous){
        this.editedAccount.properties.continuousServiceDate = new Date(this.editedAccount.properties.continuousServiceDate).toISOString();
      } else{
        this.editedAccount.properties.continuousServiceDate = null;
      }

      this.editedAccount.properties.groupHireDate = this.editedAccount.properties.continuousServiceDate;
      // if (this.editedAccount.properties.groupHireDate){
      //   this.editedAccount.properties.groupHireDate = new Date(this.editedAccount.properties.groupHireDate).toISOString();
      // } else{
      //   this.editedAccount.properties.groupHireDate = null;
      // }
      
      if (this.emailSuffix){
        this.editedAccount.email = this.editedAccount.email+this.emailSuffix;
      }
      await this.$api.account.createStaffAccount(this.$siteKey, this.editedAccount);
      this.retrieveAccounts();
    },
    pointsActiveColour(item){
      return (item.status == 0 || !item.active ? 'red' : '' 
        || item.status == 1 ? 'green' : '' 
        || item.status == 2 ? 'orange' : ''
      )
    },
    timestampFormat(timestamp) {
      var date = new Date(timestamp);
      return date.toLocaleString().split(",")[0];
    },
    async retrieveAccounts() {
      try {
        var response = await this.$api.account.getAllStaff(this.$siteKey);
        this.accounts = response.data;
        for (let i = 0; i < this.accounts.length; i++) {
          if (this.accounts[i].suspensionEndDate) {
            this.accounts[i].suspensionEndDate =
              this.accounts[i].suspensionEndDate.split("T")[0];
          }
          this.accounts[i].dateOfBirthFormatted = new Date(
            this.accounts[i].dateOfBirth
          ).toLocaleDateString();
        }
        this.loadingTable = false;
      } catch (err) {
        this.snackbarText = "Failed to load Accounts.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    async fetchAccountTransactions() {
      try {
        let response = await this.$api.transaction.getOneStaff(this.$siteKey, this.editedAccount.staffAccountID);
        this.accountTransactions = response.data;

        for (let i = 0; i < this.accountTransactions.length; i++) {
          this.accountTransactions[i].timestamp = new Date(this.accountTransactions[i].timestamp).toLocaleString();
        }
        this.snackbarText = "Transactions loaded.";
        this.snackbarColor = "primary";
        this.snackbarVisible = true;
        this.loadingTable = false;
      } catch (err) {
        this.snackbarText = "Unable to load transactions.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    async fetchSuspensions() {
      try {
        var response = await this.$api.suspension.getAll(this.$siteKey);
        this.suspensions = response.data;
      } catch (err) {
        this.snackbarText = "Failed to load suspensions";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    getCurrentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },
    viewAccount(item) {
      this.nullifyLeaving = false;
      this.nullifyContinuous = false;
      (this.dialog = true), (this.editedIndex = this.accounts.indexOf(item));
      if (item.leavingDate){
        var temp = item.leavingDate.split("T");
        item.leavingDate = temp[0]
      }
      if (item.properties.continuousServiceDate){
        if (item.properties.continuousServiceDate.includes("T")){
          var newTemp = item.properties.continuousServiceDate.split("T");
          item.properties.continuousServiceDate = newTemp[0]
        }
        else if (item.properties.continuousServiceDate.includes("/")){
          newTemp = item.properties.continuousServiceDate.split("/");
          item.properties.continuousServiceDate = newTemp[2]+"-"+newTemp[1]+"-"+newTemp[0]
        }
      }
      this.calcSuffix(item.email);
      this.editedAccount = Object.assign({}, item);
      if (this.editedAccount.properties.groupHireDate){
        this.editedAccount.properties.groupHireDate = new Date(this.editedAccount.properties.groupHireDate).toLocaleDateString()
      }
    },
    async withdrawAccount(){
      await this.$api.account.withdrawAccount(this.$siteKey, this.editedAccount)
      await this.retrieveAccounts();
    },

    async extendSuspension(){
      await this.fetchSuspensions();
      this.dialog = false;
      this.extendSuspensionDialog = true;
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
        this.retrieveAccounts();
      });
      this.suspensionDialog = false;
      this.dialog = false;
    },
    suspensionExtended(){
      if (this.editedAccount.suspensionReason && this.editedAccount.suspensionReason.reason) {
        this.editedAccount.suspensionReason = this.editedAccount.suspensionReason.reason
      }
      var enddate = new Date(this.editedAccount.suspensionEndDate)
      this.editedAccount.suspensionEndDate = new Date(
        enddate.setDate(
          enddate.getDate()+
          this.suspensions.filter((s) => s.reason == this.editedAccount.suspensionReason)[0].duration
        )
      ).toISOString().split("T")[0];
    },
    suspensionReasonChanged() {
      if (this.editedAccount.suspensionReason && this.editedAccount.suspensionReason.reason) {
        this.editedAccount.suspensionReason = this.editedAccount.suspensionReason.reason
      }
      if (
        this.suspensions.filter((s) => s.reason == this.editedAccount.suspensionReason).length == 1
      ) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        this.editedAccount.suspensionEndDate = new Date(
          today.setDate(
            today.getDate() +
            this.suspensions.filter((s) => s.reason == this.editedAccount.suspensionReason)[0].duration
          )
        ).toISOString().split("T")[0];
      }
    },
    async updateAccountSuspension() {
      if (this.editedAccount.status == 1){ this.editedAccount.status = 0}
      else if (this.editedAccount.status == 0){ this.editedAccount.status = 1}
      try {
        await this.$api.account.createStaffAccount(this.$siteKey, this.editedAccount);
        this.loading = false;
        this.snackbarText = "Suspension updated.";
        this.snackbarColor = "primary";
        this.snackbarVisible = true;
        this.close();
      } catch (err) {
        this.snackbarText = "Suspension status failed to update.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
  },
};
</script>
