export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll(sitekey) {
        return this.apiService.get(`/${sitekey}/award`)
    }

    getAllStaff(sitekey) {
        return this.apiService.get(`/${sitekey}/award/staff`)
    }

    getOne(sitekey, awardId) {
        return this.apiService.get(`/${sitekey}/award/${awardId}`)
    }

    createOne(sitekey, awardData) {
        return this.apiService.post(`/${sitekey}/award`, awardData)
    }

    editOne(sitekey, awardData) {
        return this.apiService.put(`/${sitekey}/award`, awardData)
    }

    deleteOne(sitekey, awardId) {
        return this.apiService.delete(`/${sitekey}/award/${awardId}`)
    }
}
