<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <input ref="fileUpload" type="file" hidden accept=".pdf" @change="handleFileUpload"/>
        <v-btn large class="primary" @click="chooseFiles()" v-if="!importProcessing">Upload Order Form Template</v-btn>
        <v-progress-circular
          v-else
          class="mr-6"
          :size="50"
          :width="7"
          color="impbblue"
          indeterminate
        ></v-progress-circular>
      </v-col>

      <v-col cols="auto">
        <v-btn large class="primary" @click="setReceipt=true; chooseFiles()" v-if="!importProcessing">Upload Receipt Template</v-btn>
        <v-progress-circular
          v-else
          class="mr-6"
          :size="50"
          :width="7"
          color="impbblue"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>


    <v-row align="center">
      <v-col class="py-0 my-0" cols="12" md="12" sm="12">
        <h2>Order Forms</h2>
      </v-col>
      <v-col cols="auto" v-for="file in availablePDFs" :key="file">
          <v-hover v-slot="{ hover }">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-card :elevation="hover ? 10 : 3" class="d-inline-block mx-auto mtile white--text" @click="preview(file);" v-bind="attrs" v-on="on">
                          <v-container style="text-align: center; padding: 0 !important;">
                              <v-icon color="white" style="font-size: 50px !important;">mdi-file-pdf-box</v-icon>
                              <v-card-title class="justify-center" style="word-break: normal !important; text-overflow: ellipsis !important; white-space: nowrap !important; display: block !important; overflow: hidden !important; width: 100% !important; padding: 0 !important">{{ file }}</v-card-title>
                          </v-container>
                      </v-card>
                  </template>
                  <span>{{ file }}</span>
              </v-tooltip>
          </v-hover>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="py-0 my-0" cols="12" md="12" sm="12">
        <h2>Receipt</h2>
      </v-col>
      <v-col v-if="site.receiptFileName" cols="auto">
          <v-hover v-slot="{ hover }">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-card :elevation="hover ? 10 : 3" class="d-inline-block mx-auto mtile white--text" @click="previewReceipt" v-bind="attrs" v-on="on">
                          <v-container style="text-align: center; padding: 0 !important;">
                              <v-icon color="white" style="font-size: 50px !important;">mdi-file-pdf-box</v-icon>
                              <v-card-title class="justify-center" style="word-break: normal !important; text-overflow: ellipsis !important; white-space: nowrap !important; display: block !important; overflow: hidden !important; width: 100% !important; padding: 0 !important">{{ site.receiptFileName }}</v-card-title>
                          </v-container>
                      </v-card>
                  </template>
                  <span>{{ site.receiptFileName }}</span>
              </v-tooltip>
          </v-hover>
      </v-col>
    </v-row>

    <v-dialog v-model="pdfFileDialog" width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5">{{pdfFileTitle}}</v-card-title>
        <v-card-text>
          <div v-if="pdfFileFormat=='Message'">
            {{ pdfFileMessage }}
          </div>
          <div v-else>
            <v-row class="mt-4">
              <v-col class="pt-2 pb-6" cols="4" md="4" v-for="(type, key) in neededInformation" :key="key">
                <v-menu 
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :label="key"
                      v-model="neededInformation[key]"
                      @mousedown.prevent
                    >

                    </v-text-field>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in availableTypes"
                      :key="index"
                      @click="neededInformation[key] = item"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="pdfForm=null, pdfFileDialog=false">Cancel</v-btn>
          <v-btn 
            background="blue darken-1" text 
            @click="handleFileReupload(), pdfFileDialog=false"
            :disabled="pdfFileFormat!='Message'&&neededInformation?Object.values(neededInformation).some(x=>x==null):false"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewPDFdialog" width="700" height="700" style="overflow:hidden">
      <v-card class="mb-4 py-0 px-0">
        <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
          <v-icon large class="white--text mr-4">mdi-file-eye</v-icon>
          <div>File Preview</div>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="downloadPDF()" class="mr-6">Download</v-btn> -->
          <v-icon large class="white--text mr-4" @click="previewPDFdialog=false">mdi-close</v-icon>
        </v-card-title>
        <div class="px-6 py-6">
          <v-row style="justify-content: center; align-items: center">
            <v-btn @click="currentPage--" :disabled="currentPage==1">Back</v-btn>
            <v-spacer></v-spacer>
            {{currentPage}} / {{pageCount}}
            <v-spacer></v-spacer>
            <v-btn @click="currentPage++" :disabled="currentPage==pageCount">Next</v-btn>
          </v-row>
          <div class="mt-4">
            <pdf
              :source="imageData"
              :page="currentPage"
              @internal-link-clicked="currentPage = $event"
            ></pdf>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
  export default {
    // eslint-disable-next-line
    name: 'Documents',
    components: {
      pdf,
    },
    data() {
      return {
        importProcessing: false,
        neededInformation: null,
        pdfForm: null,
        pdfFileTitle: null,
        pdfFileMessage: null,
        pdfFileFormat: null,
        availablePDFs: [],
        pdfFileDialog: false,
        previewPDFdialog: false,
        pdfFileName: null,
        currentPage: 1,
        imageData: null,
        pageCount: 0,
        site: {},
        setReceipt: false
      }
    },
    methods: {
      chooseFiles() {
        this.$refs.fileUpload.click()
      },
      async handleFileUpload(event) {
        console.log(event)
        this.importProcessing = true
        this.neededInformation = null;
        this.pdfForm = new FormData();
        this.pdfForm.append('pdfFile', event.target.files[0]);
        try{
          var response = await this.$api.pdf.uploadPDF(this.$siteKey, this.pdfForm)
          if (response.data.returnCode == 301){
            this.pdfFileTitle = "DataTypes";
            this.pdfFileFormat = "Message";
            this.pdfFileMessage = response.data.message;
            this.pdfFileDialog = true;
          }
          else if (response.data.returnCode == 300){
            this.pdfFileTitle = "Reupload"
            this.pdfFileMessage = response.data.message+", would you like to overwrite the already existing file?"
            this.pdfFileFormat='Message';
            this.pdfFileDialog = true;
          }
        }catch(error){
          this.snackbarText = 'Failed to upload PDF file'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(error)
        }
        this.importProcessing = false;
        if (this.setReceipt) {
          this.site.receiptFileName = event.target.files[0].name
          await this.$api.site.setReceiptFileName(this.$siteKey, this.site)
          this.setReceipt = false
        }
        await this.discoverPDFs();
      },
      async handleFileReupload(){
        this.pdfForm.set('reupload', true);
        if (this.neededInformation){
          //console.log(Object.values(this.neededInformation).some(x=>x==null));
          for (const [key, value] of Object.entries(this.neededInformation)) {
            this.pdfForm.set(key, value)
          }
        }
        var response = await this.$api.pdf.uploadPDF(this.$siteKey, this.pdfForm);
        if (response.data.returnCode == 301){
          this.neededInformation = response.data.fieldDict;
          this.availableTypes = response.data.types;
          this.pdfFileTitle = "DataTypes";
          this.pdfFileFormat = "Choice";
          this.pdfFileDialog = true;
        }
        else if (response.data.returnCode == 201){
          this.pdfForm = null;
          this.neededInformation = null;
          this.snackbarText = response.data.message;
          this.snackbarColor = 'primary';
          this.snackbarVisible = true;
        }
        this.discoverPDFs();
      },
      async discoverPDFs(){
        this.availablePDFs = [];
        var response = await this.$api.pdf.getAll(this.$siteKey);
        response.data.fileList.forEach((element)=>{
          if (!this.site || !this.site.receiptFileName || element.split("/")[element.split("/").length-1] != this.site.receiptFileName) {
            this.availablePDFs.push(element.split("/")[element.split("/").length-1])
          }
        })
        console.log(this.availablePDFs)
      },
      async preview(file) {
        var response = await this.$api.pdf.previewPDF(this.$siteKey, file);
        var previewPDF = new Blob([this.base64ToArrayBuffer(response.data.pdfFile)], { type: 'application/pdf' });
        this.currentPage = 1;
        this.imageData = URL.createObjectURL(previewPDF);
        this.previewPDFdialog = true;
      },
      base64ToArrayBuffer(base64) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
      },
      async getSite()
      {
        try {
          var response = await this.$api.site.getOne(this.$siteKey);
          this.site = response.data
          console.log(this.site)
        } 
        catch (err) {
          this.snackbarText = "Failed to load site data.";
          this.snackbarColor = "red";
          this.snackbarVisible = true;
          console.log(err);
        }
      },
      async previewReceipt() {
        var response = await this.$api.pdf.previewPDF(this.$siteKey, this.site.receiptFileName);
        var previewPDF = new Blob([this.base64ToArrayBuffer(response.data.pdfFile)], { type: 'application/pdf' });
        this.currentPage = 1;
        this.imageData = URL.createObjectURL(previewPDF);
        this.previewPDFdialog = true;
      }
    },
    async created() {
      await this.getSite();
      this.discoverPDFs();
    }
  }
</script>

<style>
  .mtile {
    background-color: #4f5d66 !important;
    box-shadow: rgba(0, 0, 0, 0.65) 1px 2px 5px 0px;
    border: 2px solid #1b526d !important;
    padding: 0px 5px;
    font-size: 50px;
    width: 150px;
    height: 150px;
  }
  .mtile:hover {
    background-color: #4f5d66 !important;
    box-shadow: rgba(0, 0, 0, 0.65) 2px 4px 10px 0px;
    border-color: #113758 !important;
  }
</style>