<template>
  <v-container fluid>
    <v-row>
      <v-col class="sm:pr-6" xs="12" sm="6" md="6">
        <v-card class="mb-4 py-0 px-0">
          <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
            <v-icon large class="white--text mr-4">mdi-account</v-icon>
            <div>Award Points to Staff</div>
          </v-card-title>
          <div class='py-4 pb-8 px-8'>
            <v-autocomplete
              v-model="pushAccount"
              :loading="loadingUser"
              :filter="filterAccounts"
              :items="accounts"
              :item-text="setAccountAutocompleteText"
              :search-input.sync="searchAccounts"
              hide-no-data
              @change="addAccountToSelected"
              small-chips
              clearable
              hide-details
              return-object
              prepend-icon="mdi-account-plus"
              label="Enter Staff Name or URN"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }} - {{ data.item.urn }}
              </template>
            </v-autocomplete>

            <v-textarea @keydown='validate($event)' @paste="processPasted" v-model="pasted" label="Paste List of Staff Numbers here" />

            <v-file-input
              class="mt-4 mb-0"
              accept="text/csv"
              v-model="csvFile"
              placeholder="Import Staff from CSV file"
            ></v-file-input>

            <v-btn download outlined href="accounts-import-template.csv" class="mr-4">Download CSV template</v-btn>
            <v-btn @click="importCsv" class="primary" :disabled="csvFile == null">Load Accounts</v-btn>
          </div>
        </v-card>
        <p class="my-4 text-center text-h5 grey--text">OR</p>

        <v-card class="mb-4">
          <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
            <v-icon large class="white--text mr-4">mdi-account-group</v-icon>
            <div>Award Points By Organisation</div>
          </v-card-title>
          <div class="py-4 px-8">
            <v-treeview
              v-model="selectedGroups"
              style="max-height: 400px;overflow:auto"
              :items="zones"
              selectable
              return-object
              multiple
              item-text="name"
              item-key="name"
              @input="selectOrg"
              open-all
            >
            </v-treeview>
          </div>
        </v-card>
      </v-col>

      <v-col
        class="xs:pl-0 pl-6"
        sm="6"
        md="6"
        xs="12"
      >
        <template>
          <v-card class="mb-4 py-0 px-0">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-trophy</v-icon>
              <div>Award Details</div>
            </v-card-title>

            <div class="py-4 px-8">
              <v-autocomplete
                v-model="selectedAward"
                :loading="loadingAwards"
                :items="awards"
                :search-input.sync="searchAwards"
                cache-items
                item-text="description"
                item-value="id"
                hide-no-data
                clearable
                hide-details
                return-object
                label="Select Award"
              ></v-autocomplete>

              <v-text-field
                class="mt-4"
                v-model="awardReason"
                label="Enter Award Reason"
              ></v-text-field>

              <v-checkbox
               v-model="kioskPrivate"
               class="my-2"
               label="Private (can't be seen on Kiosk)"
             ></v-checkbox>
           </div>
          </v-card>

          <p class="my-4 text-center text-h5 grey--text">AND</p>

          <v-card class="mb-4">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-account-star</v-icon>
              <div>{{selectedGroups.length || selectedAccounts.length ? 'Award Points Summary': 'No Accounts Selected'}}</div>
            </v-card-title>

            <div class="pa-8">
              <div class="mb-4" style="display: grid;grid-template-columns: auto auto" v-if="selectedAccounts.length > 0">
                <h3  class="mb-1">Selected Accounts</h3>
                <div class="mt-2" :key="user.urn" v-for="user in selectedAccounts">
                    <div class="d-flex align-center">
                      <span 
                        :class="computeAccountEligibility(user)"
                      >
                        {{user.name}} - {{ user.urn }}
                      </span>
                      <v-icon class="ml-2 m-auto"  @click="removeSelectedAccount(user)" color="red">mdi-close</v-icon>
                    </div>
                </div>
              </div>

              <div class="mb-4" v-if="selectedAward.description">
                <h3 class="mt-2">Selected Award</h3>
                <div>{{selectedGroups ? selectedAward.description : 'No groups selected.'}}</div>
              </div>
              <div class="mb-4" v-if="awardReason && selectedAward.description">
                <h3 class="mb-1">Award Reason</h3>
                <div class="mt-2">{{awardReason}}</div>
              </div>

              <p v-if="selectedAccounts.length > 0" class="text-center my-4 green--text d-flex items-center">
                <v-icon class="green--text mr-2">mdi-account-check</v-icon>
                Ready to award 
                {{ selectedAccounts.length - selectedAccounts.filter(element=>element.status==0).length }} 
                account(s).
              </p>

              <v-btn
                class="primary mt-2"
                large
                :disabled="selectedAward.description != null && awardReason != null  && selectedAccounts.length || selectedGroups.length ? false : true"
                @click="confirmDialog = true"
              >
                Award Points <v-icon class="ml-2" small>mdi-gift</v-icon>
              </v-btn>
            </div>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog
       v-model="confirmDialog"
       persistent
       max-width="350"
     >
       <v-card>
         <v-card-title class="text-h5 text-wrap break-word">
           Are you sure you want to award these points?
         </v-card-title>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="accent" text @click="confirmDialog = false">
             Cancel
           </v-btn>
           <v-btn class="primary" @click="addPoints()">
             Award Points
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>




    <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
            Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        groups: [],
        accounts: [],
        awards: [],
        zones: [],
        loadingAccounts: true,
        loadingUser: true,
        loadingAwards: true,
        searchAccounts: "",
        searchAwards: "",
        selectedAward: {},
        awardReason: null,
        selectedAccounts: [],
        allSelectedUsers: null,
        selectedGroups: [],
        snackbarText: '',
        snackbarVisible: false,
        snackbarColor: '',
        kioskPrivate: false,
        transactionData: null,
        confirmDialog: false,
        csvData: null,
        pushAccount: {},
        csvFile: null,
        csvMatches: [],
        pasted: ""
      }
    },
    props:{
      autoSelectAccount: null,
    },
    async mounted() {
      await this.retrieveGroups(),
      await this.retrieveAccounts()
      await this.retrieveAwards()
      await this.retrieveWings()
      if (this.autoSelectAccount){
        this.selectedAccounts.push(this.autoSelectAccount)
      }
    },
    methods: {
      computeAccountEligibility(account){
        return account.status == 0 ? 'red--text' : '';
      },
      async retrieveGroups() {
        try {
          var response = await this.$api.group.getOne('2') // harcoded zone id
          this.groups = response.data[0].locations
          this.loadingAccounts = false
        } catch (err) {
          console.log(err)
        }
      },
      async addPoints() {
        let self = this
        let selectedAward = this.selectedAward
        let kioskPrivate = this.kioskPrivate
        let transactionData = []
        let selectedUsers = null
        if(this.selectedAccounts.length > 0) {
          selectedUsers = this.selectedAccounts
        } else if(this.selectedGroups.length > 0) {
          selectedUsers = this.selectedGroups[0].accounts
        }

        selectedUsers.forEach((user)=>{
          transactionData.push({
            staffAccountID: user.staffAccountID, 
            transactionAmount: selectedAward.value, 
            awardReason: self.awardReason, 
            description: selectedAward.description, 
            private: kioskPrivate,
            staffTransaction: true
          })
        })
        try {
          var response = await self.$api.transaction.createOne(self.$siteKey, transactionData)
          var responseString = "";
          Object.keys(response.data).forEach(key => {
            responseString = responseString + key+": "+response.data[key]+" \n"; 
          });
          this.download("AwardPoints", responseString)
          self.snackbarVisible = true
          self.snackbarText = 'Points Awarded'
          self.snackbarColor = 'primary'
          self.confirmDialog = false
          self.selectedAccounts = [];
        } catch (error) {
          self.snackbarVisible = true
          self.snackbarText = 'Failed to Award Points'
          self.snackbarColor = 'red'
          console.log(error)
        }
        this.pushAccount = {};
        this.selectedAward = {};
        this.awardReason = null;
        this.kioskPrivate = false;
        this.transactionData = null;
        this.pasted = "";
      },
      download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      async retrieveAwards() {
        try {
          var response = await this.$api.award.getAllStaff(this.$siteKey)
          this.awards = response.data.filter(element=>element.staffAward == true && element.active==true)
          this.loadingAwards = false
        } catch (err) {
          console.log(err)
        }
      },
      async retrieveAccounts() {
        try {
          var response = await this.$api.account.getAllStaff(this.$siteKey)
          this.accounts = response.data.filter(element=>element.name!=null && element.active==true)
          this.loadingUser = false
        } catch (err) {
          console.log(err)
        }
      },
      async retrieveWings() {
        try {
          for (let i = 0; i < this.accounts.length; i++) {
            if (this.zones.filter(e => e.name === this.accounts[i].properties.orgUnit).length == 0) {
              this.zones.push({"name": this.accounts[i].properties.orgUnit, "id": i + 1, "wing": this.accounts[i].properties.orgUnit})
            }
          }
          this.loadingUser = false
        } catch (err) {
          console.log(err)
        }
      },
      filterAccounts (item) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.urn.toLowerCase()
        return textOne.indexOf(this.searchAccounts) > -1 ||
        textTwo.indexOf(this.searchAccounts) > -1
      },
      setAccountAutocompleteText(item) {
        return `${item.name} - ${item.urn}`;
      },
      removeSelectedAccount(user) {
        this.selectedAccounts.splice(this.selectedAccounts.indexOf(user), 1);
      },
      findMatches() {
        for (let item1 of this.csvData) {
          for (let item2 of this.accounts) {
            if (item1.toLowerCase() === item2.urn.toLowerCase()) {
              this.selectedAccounts.push(item2)
            }
          }
        }
      },
      validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.charCode || theEvent.which;
        if(key >= 32 && ((theEvent.ctrlKey === undefined || !theEvent.ctrlKey) && (theEvent.metaKey === undefined || !theEvent.metaKey))) {
          if(theEvent.preventDefault) theEvent.preventDefault();
          else theEvent.returnValue = false;
        }
      },
      processPasted(evt) {
        evt.preventDefault()
        var data = evt.clipboardData.getData("text")
        var array = data.split(/\r?\n/)
        for (let i = 0; i < array.length; i++) {
          if (array[i] == '') {continue;}
          var account = this.accounts.filter(a => a.urn == array[i])
          if (account.length == 1) {
            if (!this.selectedAccounts.includes(account[0])) {
              this.selectedAccounts.push(account[0])
            }
            data = data.replace(array[i], '')
            //data = data.replace(array[i] + (i == array.length - 1 ? '' : '\n'),'')
          }
          else {
            data = data.replace(array[i] + (i == array.length - 1 ? '' : '\n'), `${array[i]} - Account not found`)
          }
        }
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.collapseToEnd();
        
        var finalArray = data.split(/\r?\n/)
        var changed = true;
        while (changed){
          if (finalArray.length == 0){changed == false;}
          var discovered = false;
          finalArray.forEach((element)=>{
            if (element==''){
              finalArray.splice(finalArray.indexOf(element), 1);
              discovered = true;
            }
          })
          if (!discovered){changed = false;}
        }
        this.pasted = finalArray
      },
      importCsv() {
        const reader = new FileReader();
        reader.readAsText(this.csvFile);
        reader.onload = () => {
          this.csvData = reader.result;
          this.csvData = this.csvData.split("\r\n").map(function (urn) {
            return urn
          });
          this.findMatches()
        }
      },
      selectOrg() {
        this.selectedAccounts = []
        const regex = new RegExp("(\\d)([A-Z])-(\\d+)")
        for (let i = 0; i < this.accounts.length; i++) {
          if (Object.keys(this.accounts[i].properties).includes("orgUnit")){
            if (this.selectedGroups.filter(element=>element.name===this.accounts[i].properties.orgUnit).length > 0){
              this.selectedAccounts.push(this.accounts[i]);
            }
          }
        }
      },
      addAccountToSelected(){
        var found = false
        for (let i = 0; i < this.selectedAccounts.length; i++) {
          if(this.selectedAccounts[i].staffAccountID == this.pushAccount.staffAccountID) {
            alert('User already added')
            found = true
          }
        }
        if(found == false) {
          this.selectedAccounts.push(this.pushAccount)
        }
        this.pushAccount = {};
      }
    }
  }
</script>

<style>
.v-card__title {
    word-break: break-word;
}
</style>
