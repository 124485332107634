import Vue from 'vue'
import App from './App.vue'
import auth from '@/auth'
import router from './router'
import store from './store'
import dataApi from '@/services/dataApi'
import controlApi from '@/services/controlApi'
import acl from '@/acl'
import vuetify from './plugins/vuetify'
import VueJsonToCsv from 'vue-json-to-csv'

Vue.config.productionTip = false

const api = new dataApi()
api.useAuth(auth)
Vue.prototype.$api = api
const control = new controlApi()
control.useAuth(auth)
Vue.prototype.$control = control

auth.useRouter(router)
Vue.prototype.$auth = auth
Vue.prototype.$acl = new acl()


Vue.use(VueJsonToCsv)
Vue.component('VueJsonToCsv', VueJsonToCsv);


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
