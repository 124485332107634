export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll() {
        return this.apiService.get(`/group/`)
    }

    getOne(groupId) {
        return this.apiService.get(`/group/${groupId}`)
    }

    updateOne(group) {
        return this.apiService.put(`/group/`, group)
    }
    
    deleteOne(groupId) {
      return this.apiService.delete(`/group/${groupId}`)
    }
}
