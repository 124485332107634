export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll(siteKey) {
        return this.apiService.get(`/${siteKey}/account`)
    }

    getActive(siteKey) {
        return this.apiService.get(`/${siteKey}/account/active`)
    }

    getAllStaff(siteKey){
        return this.apiService.get(`/${siteKey}/account/activeStaff`)
    }

    getOne(siteKey, accountId) {
        return this.apiService.get(`/${siteKey}/account/${accountId}`)
    }

    updateAccount(siteKey, accountData) {
        return this.apiService.put(`/${siteKey}/account`,accountData)
    }

    withdrawAccount(siteKey, data){
        return this.apiService.post(`/${siteKey}/account/withdraw`, data)
    }

    createStaffAccount(siteKey, data){
        return this.apiService.post(`/${siteKey}/account/createStaff`, data)
    }

    uploadStaff(siteKey, data){
        return this.apiService.post(`${siteKey}/account/uploadStaff`, data)
    }

    deleteStaffAccount(siteKey, staffAccountID){
        return this.apiService.delete(`/${siteKey}/account/deleteStaff/${staffAccountID}`)
    }

    importStaffAccounts(siteKey, accounts) {
        return this.apiService.post(`/${siteKey}/account/importStaff`, accounts)
    }
}
