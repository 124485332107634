<template>
  <v-container fluid>
    <v-row>
      <v-col class="sm:pr-6" xs="12" sm="6" md="6">
        <v-card class="mb-4 py-0 px-0">
          <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
            <v-icon large class="white--text mr-4">mdi-account</v-icon>
            <div>Award Points By Prisoner</div>
          </v-card-title>
          <div class='py-4 pb-8 px-8'>
            <v-autocomplete
              v-model="pushAccount"
              :loading="loadingUser"
              :filter="filterAccounts"
              :items="accounts"
              :item-text="setAccountAutocompleteText"
              :search-input.sync="searchAccounts"
              hide-no-data
              @change="addAccountToSelected"
              small-chips
              clearable
              hide-details
              return-object
              prepend-icon="mdi-account-plus"
              label="Enter Prisoner Name or Sentence Number"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }} {{ data.item.sentenceNumber }}
              </template>
            </v-autocomplete>

            <v-textarea 
              @keydown='validate($event)' 
              @paste="processPasted" 
              v-model="pasted" label="Paste list of prison numbers here"
              :disabled="accounts.length == 0"
            />

            <v-file-input
              class="mt-4 mb-0"
              accept="text/csv"
              v-model="csvFile"
              placeholder="Import Prisoners from CSV file"
            ></v-file-input>

            <v-btn download outlined href="accounts-import-template.csv" class="mr-4">Download CSV template</v-btn>
            <v-btn @click="importCsv" class="primary" :disabled="csvFile == null">Load Accounts</v-btn>
          </div>
        </v-card>

        <p class="my-4 text-center text-h5 grey--text">OR</p>

        <v-card class="mb-4">
          <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
            <v-icon large class="white--text mr-4">mdi-account-group</v-icon>
            <div>Award Points By Group</div>
          </v-card-title>
          <div class="py-4 px-8">
            <v-treeview
              v-model="selectedGroups"
              style="max-height: 400px;overflow:auto"
              :items="wings"
              selectable
              return-object
              multiple
              item-text="name"
              item-key="name"
              @input="selectWing"
              open-all
            >
            </v-treeview>
          </div>
        </v-card>
      </v-col>

      <v-col
        class="xs:pl-0 pl-6"
        sm="6"
        md="6"
        xs="12"
      >
        <template>
          <v-card class="mb-4 py-0 px-0">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-trophy</v-icon>
              <div>Award Details</div>
            </v-card-title>

            <div class="py-4 px-8">
              <v-autocomplete
                v-model="selectedAward"
                :loading="loadingAwards"
                :items="awards"
                :search-input.sync="searchAwards"
                cache-items
                item-text="description"
                item-value="id"
                hide-no-data
                clearable
                hide-details
                return-object
                label="Select Award"
              ></v-autocomplete>

              <v-text-field
                class="mt-4"
                v-model="awardReason"
                label="Enter Award Reason"
              ></v-text-field>

              <v-checkbox
               v-model="kioskPrivate"
               class="my-2"
               label="Private (can't be seen on Kiosk)"
             ></v-checkbox>
           </div>
          </v-card>

          <p class="my-4 text-center text-h5 grey--text">AND</p>

          <v-card class="mb-4">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-account-star</v-icon>
              <div>{{selectedGroups.length || selectedAccounts.length ? 'Award Points Summary': 'No Accounts Selected'}}</div>
            </v-card-title>

            <div class="pa-8">
              <div class="mb-4" style="display: grid;grid-template-columns: auto auto" v-if="selectedAccounts.length > 0">
                <h3  class="mb-1">Selected Accounts</h3>
                <div class="mt-2" :key="user.sentenceNumber" v-for="user in selectedAccounts">
                    <div class="d-flex align-center">
                      <span 
                        :class="computeAccountEligibility(user)"
                      >
                        {{user.name + ' - ' + user.sentenceNumber}}
                      </span>
                      <v-icon class="ml-2 m-auto"  @click="removeSelectedAccount(user)" color="red">mdi-close</v-icon>
                    </div>
                </div>
              </div>

              <div class="mb-4" v-if="selectedAward.description">
                <h3 class="mt-2">Selected Award</h3>
                <div>{{selectedGroups ? selectedAward.description : 'No groups selected.'}}</div>
              </div>
              <div class="mb-4" v-if="awardReason && selectedAward.description">
                <h3 class="mb-1">Award Reason</h3>
                <div class="mt-2">{{awardReason}}</div>
              </div>

              <p v-if="selectedAccounts.length > 0" class="text-center my-4 green--text d-flex items-center">
                <v-icon class="green--text mr-2">mdi-account-check</v-icon>
                Ready to award 
                {{
                  selectedAccounts.length - selectedAccounts.filter(element=>element.pointsActive==0 || element.pointsActive==2).length +
                  (selectedAward.inductionAllowed ? 
                    selectedAccounts.filter(
                      element=>element.pointsActive == 0 && (element.suspensionReason == 'Automatic New Arrival Suspension'||element.suspensionReason == 'Wing Movement')
                    ).length 
                  : 0)
                  -
                  computeAccountAward(selectedAccounts)
                }} 
                account(s) of {{ selectedAccounts.length }}.
              </p>

              <v-btn
                class="primary mt-2"
                large
                :disabled="selectedAward.description != null && awardReason != null  && selectedAccounts.length || selectedGroups.length ? false : true"
                @click="confirmDialog = true"
              >
                Award Points <v-icon class="ml-2" small>mdi-gift</v-icon>
              </v-btn>
            </div>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog
       v-model="confirmDialog"
       persistent
       max-width="350"
     >
       <v-card>
         <v-card-title class="text-h5 text-wrap break-word">
           {{ awarding ? "Awarding points..." : "Are you sure you want to award these points?"}}
         </v-card-title>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="accent" text v-if="!awarding" @click="confirmDialog = false">
             Cancel
           </v-btn>
           <v-btn class="primary" v-if="!awarding" @click="addPoints()">
             Award Points
           </v-btn>
           <v-progress-circular
              v-else
              :size="45"
              :width="7"
              color="impbblue"
              indeterminate
              style="right: 40%;"
            ></v-progress-circular>
         </v-card-actions>
       </v-card>
     </v-dialog>




    <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
            Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        groups: [],
        accounts: [],
        awards: [],
        zones: [],
        wings: [],
        site: {},
        loadingAccounts: true,
        loadingUser: true,
        loadingAwards: true,
        searchAccounts: "",
        searchAwards: "",
        selectedAward: {},
        awardReason: null,
        selectedAccounts: [],
        allSelectedUsers: null,
        awarding: false,
        selectedGroups: [],
        snackbarText: '',
        snackbarVisible: false,
        snackbarColor: '',
        kioskPrivate: false,
        transactionData: null,
        confirmDialog: false,
        csvData: null,
        pushAccount: {},
        csvFile: null,
        csvMatches: [],
        pasted: "",
      }
    },
    props:{
      autoSelectAccount: null,
    },
    async mounted() {
      await this.retrieveSite()
      await this.retrieveAccounts()
      await this.retrieveAwards()
      await this.retrieveWings()

      if (this.autoSelectAccount){
        this.selectedAccounts.push(this.autoSelectAccount)
      }
    },

    methods: {
      computeAccountEligibility(account){
        var accountClass = [];
        if (this.selectedAward){
          if (account.pointsActive == 2) {
            accountClass = 'orange--text'
          }
          else if (this.selectedAward.inductionAllowed){
            accountClass = account.pointsActive == 0 && (account.suspensionReason == 'Automatic New Arrival Suspension'|| account.suspensionReason == 'Wing Movement') 
            ? 'cyan--text'
            :'' || (account.pointsActive == 0 ? 'red--text' : '')
          }
          else if (account.pointsActive == 0) { accountClass = 'red--text'}
        }
        else{ accountClass = account.pointsActive == 0 ? 'red--text' : account.pointsActive == 2 ? 'orange--text' : ''}
        if (Object.keys(this.selectedAward).length != 0 && this.selectedAward.availabilityRules){
          var rules = this.selectedAward.availabilityRules.split(";")
          for (var i = 0; i < rules.length; i++){
            if (rules[i].split("&")[0].split("=")[1] == account.location.zoneID){
              accountClass = 'red--text'
              break;
            }
          }
        }
        return accountClass;
      },
      computeAccountAward(selectedAccounts){
        var total = 0;
        if (Object.keys(this.selectedAward).length != 0){
          if (this.selectedAward.availabilityRules) {
            var rules = this.selectedAward.availabilityRules.split(";")
            for (var x = 0; x < selectedAccounts.length; x++){
              for (var i = 0; i < rules.length; i++){
                if (rules[i].split("&")[0].split("=")[1] == selectedAccounts[x].location.zoneID){
                  total += 1
                }
              }
            }
          }
        }
        return total;
      },
      async retrieveSite() {
        try {
          var response = await this.$api.site.getOne(this.$siteKey) // harcoded zone id
          this.site = response.data
        } catch (err) {
          console.log(err)
        }
      },
      async addPoints() {
        let self = this
        let selectedAward = this.selectedAward
        let kioskPrivate = this.kioskPrivate
        let transactionData = []
        let selectedUsers = null
        if(this.selectedAccounts.length > 0) {
          selectedUsers = this.selectedAccounts
        } else if(this.selectedGroups.length > 0) {
          selectedUsers = this.selectedGroups[0].accounts
        }

        if (this.selectedAward.availabilityRules) {
          var rules = this.selectedAward.availabilityRules.split(";")
          for (var x = 0; x < selectedUsers.length; x++){
            for (var i = 0; i < rules.length; i++){
              if (rules[i].split("&")[0].split("=")[1] == selectedUsers[x].location.zoneID){
                selectedUsers.splice(x, 1);
              }
            }
          }
        }

        selectedUsers.map(function(user){
          if (user.pointsActive == 1 || (selectedAward.inductionAllowed && user.pointsActive == 0 && (user.suspensionReason == 'Automatic New Arrival Suspension'||user.suspensionReason == 'Wing Movement'))){
            transactionData.push({
              accountID: user.accountID, 
              transactionAmount: selectedAward.value, 
              awardReason: self.awardReason, 
              description: selectedAward.description, 
              private: kioskPrivate,
              staffTransaction: false
            })
          }
        })
        try {
            self.awarding = true
            await self.$api.transaction.createOne(self.$siteKey, transactionData)
            self.awarding = false
            self.snackbarVisible = true
            self.snackbarText = 'Points Awarded'
            self.snackbarColor = 'primary'
            self.confirmDialog = false
            self.selectedAccounts = [];
        } catch (error) {
          self.snackbarVisible = true
          self.snackbarText = 'Failed to Award Points'
          self.snackbarColor = 'red'
          console.log(error)
        }
        this.pushAccount = {};
        this.selectedAward = {};
        this.awardReason = null;
        this.kioskPrivate = false;
        this.transactionData = null;
        this.pasted = "";
        this.selectedGroups = [];
      },
      async retrieveAwards() {
        try {
          var awardResponse = await this.$api.award.getAll(this.$siteKey)
          var zoneResponse = await this.$api.zones.getAllZones(this.$siteKey)
          this.zones = zoneResponse.data
          this.awards = awardResponse.data.filter(element=>element.staffAward == false && element.active==true)
          this.loadingAwards = false
        } catch (err) {
          console.log(err)
        }
      },
      async retrieveAccounts() {
        try {
          var response = await this.$api.account.getActive(this.$siteKey)
          this.accounts = response.data.filter(element=>element.name!=null)
          //this.accounts = response.data
          this.loadingUser = false
        } catch (err) {
          console.log(err)
        }
      },
      async retrieveWings() {
        try {
          var response = await this.$api.zones.getAllWings(this.$siteKey)
          for (let i = 0; i < response.data.length; i++) {
            this.wings.push({"name": response.data[i] + " Wing", "id": i + 1, "wing": response.data[i]})
          }
          this.loadingUser = false
        } catch (err) {
          console.log(err)
        }
      },
      filterAccounts (item) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.sentenceNumber.toLowerCase()
        return textOne.indexOf(this.searchAccounts.toLowerCase()) > -1 ||
        textTwo.indexOf(this.searchAccounts.toLowerCase()) > -1
      },
      setAccountAutocompleteText(item) {
        return `${item.name} - #${item.sentenceNumber}`;
      },
      removeSelectedAccount(user) {
        this.selectedAccounts.splice(this.selectedAccounts.indexOf(user), 1);
      },
      findMatches() {
        for (let item1 of this.csvData) {
          for (let item2 of this.accounts) {
            if (item1.toLowerCase() === item2.sentenceNumber.toLowerCase()) {
              this.selectedAccounts.push(item2)
            }
          }
        }
      },
      validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.charCode || theEvent.which;
        if(key >= 32 && ((theEvent.ctrlKey === undefined || !theEvent.ctrlKey) && (theEvent.metaKey === undefined || !theEvent.metaKey))) {
          if(theEvent.preventDefault) theEvent.preventDefault();
          else theEvent.returnValue = false;
        }
      },
      processPasted(evt) {
        evt.preventDefault()
        var data = evt.clipboardData.getData("text")
        var array = data.split(/\r?\n/)
        for (let i = 0; i < array.length; i++) {
          if (array[i] == '') {continue;}
          var account = this.accounts.filter(a => a.sentenceNumber == array[i])
          if (account.length == 1) {
            if (!this.selectedAccounts.includes(account[0])) {
              this.selectedAccounts.push(account[0])
            }
            data = data.replace(array[i], '')
            //data = data.replace(array[i] + (i == array.length - 1 ? '' : '\n'),'')
          }
          else {
            data = data.replace(array[i] + (i == array.length - 1 ? '' : '\n'), `${array[i]} - Account not found`)
          }
        }
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.collapseToEnd();
        
        var finalArray = data.split(/\r?\n/)
        var changed = true;
        while (changed){
          if (finalArray.length == 0){changed == false;}
          var discovered = false;
          finalArray.forEach((element)=>{
            if (element==''){
              finalArray.splice(finalArray.indexOf(element), 1);
              discovered = true;
            }
          })
          if (!discovered){changed = false;}
        }
        this.pasted = finalArray
      },
      importCsv() {
        const reader = new FileReader();
        reader.readAsText(this.csvFile);
        reader.onload = () => {
          this.csvData = reader.result;
          this.csvData = this.csvData.split("\r\n").map(function (accountSentenceNumber) {
            return accountSentenceNumber
          });
          this.findMatches()
        }
      },
      selectWing() {
        this.selectedAccounts = []
        const regex = new RegExp(this.site.locationRegex)
        for (let i = 0; i < this.accounts.length; i++) {
          var found = this.accounts[i].location.description.match(regex)
          if (found) {
            if (this.selectedGroups.filter(g => g.wing == found[2]).length > 0) {
              this.selectedAccounts.push(this.accounts[i])
            }
          }
        }
      },
      addAccountToSelected(){
        var found = false
        for (let i = 0; i < this.selectedAccounts.length; i++) {
          if(this.selectedAccounts[i].accountID == this.pushAccount.accountID) {
            alert('User already added')
            found = true
          }
        }
        if(found == false) {
          this.selectedAccounts.push(this.pushAccount)
        }
        this.pushAccount = {};
      }
    }
  }
</script>

<style>
.v-card__title {
    word-break: break-word;
}
</style>
