import { dataApiServer, pdfApiServer, AllowedPages } from '../../config'
import axios from 'axios'

import incentiveRoutes from './routes/incentiveRoutes'
import awardRoutes from './routes/awardRoutes'
import accountRoutes from './routes/accountRoutes'
import zonesRoutes from './routes/zonesRoutes'
import groupRoutes from './routes/groupRoutes'
import transactionRoutes from './routes/transactionRoutes'
import suspensionRoutes from './routes/suspensionRoutes'
import siteRoutes from './routes/siteRoutes'
import memberRoutes from './routes/memberRoutes'

import pdfRoutes from './routes/pdfRoutes'


export default class apiV2Service {
    constructor() {
        this.apiService = axios.create()
        this.apiService.defaults.baseURL = (dataApiServer.ssl ? 'https' : 'http') + '://' + dataApiServer.host + ((dataApiServer.ssl && dataApiServer.port == '443') || (!dataApiServer.ssl && dataApiServer.port == '80') || !dataApiServer.port ? '' : ':' + dataApiServer.port) + '/'
        this.allowedPages = AllowedPages
        this.zones = new zonesRoutes(this.apiService)
        this.incentive = new incentiveRoutes(this.apiService)
        this.award = new awardRoutes(this.apiService)
        this.account = new accountRoutes(this.apiService)
        this.member = new memberRoutes(this.apiService)
        this.group = new groupRoutes(this.apiService)
        this.transaction = new transactionRoutes(this.apiService)
        this.suspension = new suspensionRoutes(this.apiService)
        this.site = new siteRoutes(this.apiService)

        this.pdfService = axios.create()
        this.pdfService.defaults.baseURL = (pdfApiServer.ssl ? 'https' : 'http') + '://' + pdfApiServer.host + ((pdfApiServer.ssl && pdfApiServer.port == '443') || (!pdfApiServer.ssl && pdfApiServer.port == '80') || !pdfApiServer.port ? '' : ':' + pdfApiServer.port) + '/'
        this.pdfService.interceptors.request.use(config => {
            config.params = config.params || {}
            config.params['api-version'] = '1.1'
            return config
        })
        this.pdf = new pdfRoutes(this.pdfService)

        this.controlService = axios.create()
    }

    useAuth(auth) {
         this.apiService.interceptors.request.use(config => {
             config.headers.Authorization = `Bearer ${auth.accessToken}`
             return config
         })
     }
 }