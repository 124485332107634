
<template>
  <v-navigation-drawer
    v-if="drawer"
    class="elevation-1 primary-500 px-0 py-0 color-primary"
    clipped
    expand-on-hover
    permanent
    app
  >

    <v-list dense class="mx-0" nav flat>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        exact-active-class="primary white--text elevation-4"
        class="py-1 my-2 px-2 justify-start"
        
        v-show="constructedList.includes(item.title)"
      >
        <v-list-group 
          v-if="item.group" 
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(subitem, i) in item.group"
            :key="i"
            :to="{ name: subitem.title }"
          >
            <v-list-item-icon>
              <v-icon>{{ subitem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subitem.title.split(/(?=[A-Z])/)[0] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        
        <v-list-item v-else :to="{ name: item.title }">
          <v-list-item-icon class="text-center justify-center w-20">
            <v-icon exact-active-class="white--text">{{ item.icon }}</v-icon>
          </v-list-item-icon>
  
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Sidebar',
  props: {
    drawer: Boolean,
    mini: Boolean,
  },
  data() {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Members', icon: 'mdi-account-multiple' },
        
        { 
          title: 'Prisoners', 
          icon: 'mdi-account-multiple-outline', 
          group: [
            { title: 'PointsPrisoner', icon: 'mdi-account-star' },
            { title: 'AwardsPrisoner', icon: 'mdi-gift' },
            { title: 'TransactionsPrisoner', icon: 'mdi-list-box' },
            { title: 'AccountsPrisoner', icon: 'mdi-account-multiple' },
            { title: 'IncentivesPrisoner', icon: 'mdi-trophy' },
            { title: 'SuspensionsPrisoner', icon: 'mdi-close-octagon'},
          ]
        },
        {
          title: 'Staff',
          icon: 'mdi-account-multiple-outline',
          group: [
            { title: 'PointsStaff', icon: 'mdi-account-star' },
            { title: 'AwardsStaff', icon: 'mdi-gift' },
            { title: 'TransactionsStaff', icon: 'mdi-list-box' },
            { title: 'AccountsStaff', icon: 'mdi-account-multiple' },
            { title: 'IncentivesStaff', icon: 'mdi-trophy' },
          ]
        },       
        
        { title: 'Settings', icon: 'mdi-cog' },
        { title: 'Documents', icon: 'mdi-file-document-outline'},
        { title: 'Noticeboard', icon: 'mdi-bulletin-board' }
        // { title: 'Residents', icon: 'mdi-account' },
        // { title: 'Zones', icon: 'mdi-map-marker-multiple' },
      ],
      constructedList: [],
      itemToSearch: null
    }
  },
  // watch:{
  //   userRole(){
  //     this.getConstructedList();
  //   }
  // },
  mounted(){
    this.getConstructedList();
  },  
  methods:{
    getConstructedList(){
      this.constructedList = [];
      this.itemToSearch = this.$userRole;
      for (var i = 0; i < Object.keys(this.$api.allowedPages).length; i++){
        if (Object.keys(this.$api.allowedPages)[i] == this.itemToSearch){
          if(Object.keys(this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]]).includes("Allowed")){
            this.constructedList = this.constructedList.concat(this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]].Allowed)
          }
          if(Object.keys(this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]]).includes("Child")){
            this.itemToSearch = this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]].Child
          }
        }
      }
      if (this.constructedList.length == 0){ this.$emit("notAuthorised", true); return false }
      else{ this.$emit("notAuthorised", false); }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
