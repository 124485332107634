<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    chartLabels: Array,
    chartDataSets: Array,
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.handleChartClick,
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toFixed(0);
              },
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      var chartData = {
        labels: [],
        datasets: [],
      };
      chartData.datasets = this.chartDataSets;
      chartData.labels = this.chartLabels;
      return chartData;
    },
  },
  methods:{
    handleChartClick(evt, element){
      this.$emit("onSelect", {index: element[0].index, dataIndex: element[0].datasetIndex})
    }
  }
};
</script>
