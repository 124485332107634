<template>
  <v-container fluid>
    <v-data-table
      :headers="tableAccountHeaders"
      :items="filteredAccounts"
      :search="search"
      class="elevation-1 px-6 py-6"
      :loading="loadingTable"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="3">
            <v-toolbar-title class="text-h4 mt-6"
              >Accounts
              <span v-if="accounts.length > 0"
                >({{ accounts.length }})</span
              ></v-toolbar-title
            >
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mt-6"
            ></v-text-field>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="1" style="position: relative;">
              <v-btn style="position: absolute; left: 0; bottom: 0;" class="primary" @click="getExportData" v-if="!exportProcessing">Export</v-btn>
              <v-progress-circular
                v-else
                :size="50"
                :width="7"
                color="impbblue"
                indeterminate
              ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-center align-center">
              <div class="d-inline-block text-center mt-6 mr-10 align-center">
                Nominal Status:
                <v-radio-group
                  class="mt-2"
                  mandatory
                  row
                  v-model="filterNominalStatus"
                >
                  <v-radio value="1" label="Live"></v-radio>
                  <v-radio value="0" label="Discharged"></v-radio>
                </v-radio-group>
              </div>

              <div class="d-inline-block text-center mt-6 mr-10 align-center">
                Points Status:
                <v-radio-group class="mt-2" row v-model="filterPointsStatus">
                  <v-radio value="1" label="Active"></v-radio>
                  <v-radio value="0" label="Suspended"></v-radio>
                  <v-radio value="3" label="Induction"></v-radio>
                  <v-radio value="2" label="Withdrawn"></v-radio>
                </v-radio-group>
              </div>

              <div class="d-inline-block text-center mt-6 mr-10 align-center">
                IEP:
                <v-radio-group class="mt-2" row v-model="filterIEP">
                  <v-radio value="E" label="Enhanced"></v-radio>
                  <v-radio value="S" label="Standard"></v-radio>
                  <v-radio value="B" label="Basic"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.dateOfBirthFormatted`]="{ item }">
        {{ timestampFormat(item.dateOfBirth) }}
      </template>
      <template v-slot:[`item.suspensionEndDate`]="{ item }">
        {{ item.suspensionEndDate != null? timestampFormat(item.suspensionEndDate): "" }}
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          v-model="item.active"
          color="success"
          dense
          disabled
          :value="item.active"
        ></v-switch>
      </template>

      <template v-slot:[`item.pointsActive`]="{ item }">
        <v-chip
          small
          text-color="white"
          :color="pointsActiveColour(item)"
        >
          {{item.pointsActive == 0 && (item.suspensionReason == 'Automatic New Arrival Suspension'||item.suspensionReason == 'Wing Movement') ? 'Induction' : pointsActiveText[item.pointsActive]}}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium color="primary" class="mr-2" @click="viewAccount(item), fetchAccountTransactions()"
          >mdi-account-eye</v-icon
        >
        <v-icon medium color="primary" class="mr-2" @click="$router.push({name: 'PointsPrisoner', params: { 'autoSelectAccount': item}})"
          >mdi-trophy</v-icon
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-if="snackbarVisible === true"
      v-model="snackbarVisible"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <template v-slot:[`action`]="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card>
        <v-card-title
          class="
            mb-2
            justify-start
            px-4
            py-6
            primary
            white--text
            text-h4
            justify-center
          "
        >
          Account:
          {{ editedAccount.name + " - " + editedAccount.sentenceNumber }}
        </v-card-title>
        <v-container class="px-8">
          <v-tabs>
            <v-tab>
              <v-icon class="text--black" left> mdi-account-view </v-icon>
              Account Details
            </v-tab>
            <v-tab>
              <v-icon class="text--black" left> mdi-accounts </v-icon>
              Transactions
            </v-tab>

            <v-tab-item class="mt-6">
              <v-card flat>
                <v-row>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.name"
                      readonly
                      filled
                      label="Name"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.pin"
                      readonly
                      filled
                      label="Pin"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.sentenceNumber"
                      readonly
                      filled
                      label="Sentence Number"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      :value="new Date(editedAccount.dateOfBirth).toLocaleDateString()"
                      readonly
                      filled
                      label="Date Of Birth"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.religion"
                      readonly
                      filled
                      label="Religion"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.race"
                      readonly
                      filled
                      label="Race"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.balance"
                      readonly
                      filled
                      label="Balance"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.location.description"
                      readonly
                      filled
                      label="Location"
                    />
                  </v-col>
                  <v-col class="py-2" cols="4">
                    <v-text-field
                      v-model="editedAccount.iep"
                      readonly
                      filled
                      label="IEP"
                    />
                  </v-col>

                  <v-col cols="4" class="pt-6">
                    <div class="text-center d-flex align-center mb-4">
                      <label class="ma-0 mr-2">Points Status:</label>
                      <v-chip
                        small
                        text-color="white"
                        class="ma-0"
                        :color="pointsActiveColour(editedAccount)"
                      >
                        {{editedAccount.pointsActive == 0 && (editedAccount.suspensionReason == 'Automatic New Arrival Suspension'||editedAccount.suspensionReason == 'Wing Movement') ? 'Induction' :pointsActiveText[editedAccount.pointsActive]}}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col cols="4" class="pt-2">
                    <v-text-field
                      v-if="editedAccount.suspensionEndDate"
                      :value="editedAccount.suspensionEndDate.split('T')[0]"
                      label="Suspension End Date"
                      class="mt-0"
                      hide-details
                      filled
                      readonly
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-2" cols="4" v-if="editedAccount.pointsActive == 0">
                    <v-text-field
                      v-model="editedAccount.suspensionReason"
                      readonly
                      filled
                      label="Suspension Reason"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>

            <v-tab-item class="mt-6">
              <v-card flat>
                <!--DataTable here-->
                <v-data-table
                  :headers="tableTransactionHeaders"
                  :items="accountTransactions"
                >
                  <template v-slot:[`item.active`]="{ item }">
                    <v-simple-checkbox
                      color="primary"
                      v-model="item.active"
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      v-if="item.transactionMonetaryValue > 0"
                      small
                      class="ma-2"
                      text-color="white"
                      :color="
                        item.status == 0
                          ? 'green'
                          : '' || item.status == 1
                          ? 'orange'
                          : '' || item.status == 2
                          ? 'red'
                          : '' 
                      "
                    >
                      {{ item.status == true ? "Reviewed" : "Not Reviewed" }}
                      {{
                        item.status == 0
                          ? "Complete"
                          : "" || item.status == 1
                          ? "Pending"
                          : "" || item.status == 2
                          ? "Declined"
                          : ""
                      }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.timestamp`]="{ item }">
                    <span>{{ item.timestamp.toLocaleString() }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-card-actions class="mt-8">
            <v-spacer></v-spacer>
            <v-btn color="primary" large @click="dialog = false">Close</v-btn>
            <v-btn @click="withdrawAccount()" color="orange" large>
              {{ editedAccount.withdrawn ? "Join":"Withdraw" }}
            </v-btn>
            <v-btn @click="extendSuspension()" class="accent" large v-if="editedAccount.pointsActive==0">
              Extend Suspension
            </v-btn>
            <v-btn @click="suspendAccount()" class="accent" large v-if="editedAccount.pointsActive<2">{{
              editedAccount.pointsActive == 1
                ? "Suspend Account"
                : "Lift Account Suspension"
            }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="extendSuspensionDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          {{ editedAccount.name + " - " + editedAccount.sentenceNumber }}
        </v-card-title>
        <v-container>
          <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="datePicker"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computeTempDate"
                      label="Suspension End Date"
                      prepend-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tempSuspensionEndDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePicker.save(datePicker)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-combobox
                  v-model="extensionSuspensionReason"
                  prepend-icon="mdi-comment-text"
                  label="New Suspension Reason"
                  :items="suspensions.filter((s) => s.type == 'suspension')"
                  item-text="reason"
                  @change="suspensionExtended"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                large
                @click="extendSuspensionDialog = false"
                >Cancel</v-btn
              >
              <v-btn class="accent" large @click="updateAccountSuspension('extension')">
                Save Changes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="withdrawDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          {{ editedAccount.name + " - " + editedAccount.sentenceNumber }}
        </v-card-title>
        <v-container>
          <v-card flat>
            <v-row>
              <v-col class="pt-0" cols="12">
                <v-combobox
                  v-model="withdrawReason"
                  prepend-icon="mdi-comment-text"
                  label="Withdrawal Reason"
                  :items="suspensions.filter((s) => s.type == 'withdraw')"
                  item-text="reason"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                large
                @click="withdrawDialog = false"
                >Cancel</v-btn
              >
              <v-btn class="accent" large @click="withdrawAccountWithReason()">
                Save Changes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="suspensionDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          {{ editedAccount.name + " - " + editedAccount.sentenceNumber }}
        </v-card-title>
        <v-container class="px-8">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="datePicker"
                  v-if="editedAccount.pointsActive == 1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computeDate"
                      label="Suspension End Date"
                      prepend-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedAccount.suspensionEndDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePicker.save(datePicker)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-combobox
                  v-model="editedAccount.suspensionReason"
                  prepend-icon="mdi-comment-text"
                  :label="editedAccount.pointsActive == 1 ? 'Suspension Reason' : 'Unsuspension Reason'"
                  :items="suspensions.filter((s) => (s.type == 'suspension' && editedAccount.pointsActive) || (s.type == 'lift' && !editedAccount.pointsActive))"
                  item-text="reason"
                  @change="suspensionReasonChanged"
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                large
                @click="suspensionDialog = false"
                >Cancel</v-btn
              >
              <v-btn class="accent" large @click="updateAccountSuspension()">
                {{editedAccount.pointsActive == 1 ? "Suspend Account" : "Unsuspend Account"}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      pointsActiveText: ["Suspended", "Active", "Withdrawn"],
      accounts: [],
      accountTransactions: [],
      suspensions: [],
      loadingTable: true,
      datePicker: false,
      tableAccountHeaders: [
        // {text: "Account ID", value: "accountID"},
        { text: "Name", value: "name" },
        { text: "Pin", value: "pin" },
        { text: "Prisoner Number", value: "sentenceNumber" },
        { text: "Cell", value: "location.description"},
        { text: "IEP", value: "iep" },
        // {text: "Active", value: "active"},
        { text: "Points Status", value: "pointsActive", width: "160px" },
        { text: "Suspension Reason", value: "suspensionReason"},
        { text: "Suspension End Date", value: "suspensionEndDate" },
        { text: "Date Of Birth", value: "dateOfBirthFormatted" },
        { text: "Balance", value: "balance", align: "end" },
        {
          text: "Action",
          cellClass: "py-2",
          value: "actions",
          sortable: false,
          align: "end",
          width: "100px",
        },
      ],
      tableTransactionHeaders: [
        { text: "Date", align: "start", sortable: true, value: "timestamp" },
        { text: "Cell", value: "accountLocation" },
        { text: "Description", value: "description" },
        { text: 'Reason', value: 'awardReason' },
        { text: "Status", value: "status", align: "end" },
        { text: "Cost (£)", value: "cost", align: "end" },
        { text: "Amount", value: "transactionAmount", align: "end" },
        { text: "Balance", value: "accountBalance", align: "end" },
      ],
      editedAccount: {
        accountID: null,
        name: null,
        pin: null,
        sentenceNumber: null,
        active: Boolean,
        locationID: Number,
        pointsActive: Boolean,
        suspensionEndDate: null,
        suspensionReason: null,
        dateOfBirth: null,
        religion: null,
        race: null,
        balance: Number,
        location: { description: null },
        transactions: null,
        withdrawn: Boolean,
      },
      filterPointsStatus: null,
      filterIEP: null,
      filterNominalStatus: 1,
      tempSuspensionEndDate: "",
      snackbarVisible: false,
      dialog: false,
      suspensionDialog: false,
      extendSuspensionDialog: false,
      withdrawDialog: false,
      search: null,
      exportProcessing: false,
      extensionSuspensionReason: "",
      withdrawReason: ""
    };
  },
  components: {},
  async mounted() {
    await this.retrieveAccounts();
  },
  computed: {
    filteredAccounts() {
      return this.filterByNominalAndPoints(this.accounts);
    },
    computeDate(){
      return this.formatDate(this.editedAccount.suspensionEndDate)
    },
    computeTempDate(){
      return this.formatDate(this.tempSuspensionEndDate);
    }
  },
  methods: {
    objectToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
          const values = headers.map(header => {
              const val = row[header]
              return `"${val}"`;
          });
          csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    },
    async getExportData(){
      this.exportProcessing = true;
      let csvContent = "data:text/csv;charset=utf-8," + this.objectToCsv(this.filteredAccounts.map(function(account) { return {
        name: account.name,
        pin: account.pin,
        sentenceNumber: account.sentenceNumber,
        cell: account.location.description,
        iep: account.iep,
        pointsActive: account.pointsActive,
        suspensionReason: account.suspensionReason,
        suspensionEndDate: account.suspensionEndDate,
        dateOfBirth: account.dateOfBirth,
        balance: account.balance
      }}));
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'CRIS_Prisoner_Accounts_Export_' + this.getCurrentDate());
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file.
      this.exportProcessing = false;
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('T')[0].split('-')
      return `${day}/${month}/${year}`
    },
    pointsActiveColour(item){
      return ((item.pointsActive == 0 && (item.suspensionReason == 'Automatic New Arrival Suspension'||item.suspensionReason == 'Wing Movement')) ? 'cyan' : ''
        || item.pointsActive == 0 ? 'red' : '' 
        || item.pointsActive == 1 ? 'green' : '' 
        || item.pointsActive == 2 ? 'orange' : ''
      )
    },
    timestampFormat(timestamp) {
      var date = new Date(timestamp);
      return date.toLocaleString().split(",")[0];
    },
    async retrieveAccounts() {
      try {
        var response = await this.$api.account.getAll(this.$siteKey);
        this.accounts = response.data;
        this.loadingTable = false;
      } catch (err) {
        this.snackbarText = "Failed to load Accounts.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    async fetchAccountTransactions() {
      try {
        let response = await this.$api.transaction.getOne(this.$siteKey, this.editedAccount.accountID);
        this.accountTransactions = response.data;

        for (let i = 0; i < this.accountTransactions.length; i++) {
          this.accountTransactions[i].timestamp = new Date(this.accountTransactions[i].timestamp);
        }
        this.snackbarText = "Transactions loaded.";
        this.snackbarColor = "primary";
        this.snackbarVisible = true;
        this.loadingTable = false;
      } catch (err) {
        this.snackbarText = "Unable to load transactions.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    async fetchSuspensions() {
      try {
        var response = await this.$api.suspension.getAll(this.$siteKey);
        this.suspensions = response.data;
      } catch (err) {
        this.snackbarText = "Failed to load suspensions";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    getCurrentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },
    filterByNominalAndPoints() {
      var filter = {}
      if (this.filterNominalStatus != null) {
        filter.active = this.filterNominalStatus
      }
      if (this.filterPointsStatus != null) {
        filter.pointsActive = this.filterPointsStatus
        if (filter.pointsActive == 3) {
          filter.pointsActive = 0
          filter.suspensionReason = "Automatic New Arrival Suspension"
        }
      }
      if (this.filterIEP != null) {
        filter.iep = this.filterIEP
      }
      return this.accounts.filter(function(item) {
        for(var key in filter) {
          if (item[key] === undefined || item[key] != filter[key])
            return false
        }
        return true
      })
    },
    viewAccount(item) {
      (this.dialog = true), (this.editedIndex = this.accounts.indexOf(item));
      this.editedAccount = Object.assign({}, item);
    },
    async withdrawAccount(){
      if (this.editedAccount.withdrawn) {
        this.editedAccount.withdrawn = !this.editedAccount.withdrawn
        await this.$api.account.withdrawAccount(this.$siteKey, this.editedAccount)
        await this.retrieveAccounts();
      }
      else {
        await this.fetchSuspensions()
        this.withdrawDialog = true
      }
    },
    async withdrawAccountWithReason() {
      this.editedAccount.withdrawn = !this.editedAccount.withdrawn
      this.editedAccount.suspensionReason = this.withdrawReason.reason
      await this.$api.account.withdrawAccount(this.$siteKey, this.editedAccount)
      await this.retrieveAccounts();
      this.withdrawDialog = false
    },
    async extendSuspension(){
      await this.fetchSuspensions();
      this.tempSuspensionEndDate = this.editedAccount.suspensionEndDate
      this.dialog = false;
      this.extendSuspensionDialog = true;
    },
    async suspendAccount() {
      await this.fetchSuspensions();
      if (this.editedAccount.pointsActive == 0) {
        this.editedAccount.suspensionEndDate = null;
        this.editedAccount.suspensionReason = null;
      }
      (this.dialog = false), (this.suspensionDialog = true);
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
        this.extensionSuspensionReason = ""
        this.retrieveAccounts();
      });
      this.suspensionDialog = false;
      this.dialog = false;
    },
    suspensionExtended(){
      if (this.extensionSuspensionReason && this.extensionSuspensionReason.reason) {
        var enddate = new Date(this.editedAccount.suspensionEndDate)
        this.tempSuspensionEndDate = new Date(
          enddate.setDate(
            enddate.getDate()+
            this.suspensions.filter((s) => s.suspensionID == this.extensionSuspensionReason.suspensionID)[0].duration
          )
        ).toISOString().split("T")[0];
        this.extensionSuspensionReason = this.extensionSuspensionReason.reason
      }
    },
    suspensionReasonChanged() {
      if (this.editedAccount.suspensionReason && this.editedAccount.suspensionReason.reason) {
        this.editedAccount.suspensionReason = this.editedAccount.suspensionReason.reason
      }
      if (
        this.suspensions.filter((s) => s.reason == this.editedAccount.suspensionReason).length == 1
      ) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        this.editedAccount.suspensionEndDate = new Date(
          today.setDate(
            today.getDate() +
            this.suspensions.filter((s) => s.reason == this.editedAccount.suspensionReason)[0].duration
          )
        ).toISOString().split("T")[0];
      }
    },
    async updateAccountSuspension(suspension = null) {
      if (this.extendSuspensionDialog) {
         this.extendSuspensionDialog = false
         this.editedAccount.suspensionEndDate = this.tempSuspensionEndDate
         this.editedAccount.suspensionReason = this.extensionSuspensionReason
      }
      if (suspension == null){
        if (this.editedAccount.pointsActive == 1){ this.editedAccount.pointsActive = 0}
        else if (this.editedAccount.pointsActive == 0){ this.editedAccount.pointsActive = 1}
      }
      //this.editedAccount.pointsActive = !this.editedAccount.pointsActive;
      if (this.editedAccount.pointsActive == 1) {
        this.editedAccount.suspensionEndDate = null;
      }
      try {
        await this.$api.account.updateAccount(this.$siteKey, this.editedAccount);
        this.loading = false;
        this.snackbarText = "Suspension updated.";
        this.snackbarColor = "primary";
        this.snackbarVisible = true;
        this.close();
      } catch (err) {
        this.snackbarText = "Suspension status failed to update.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
  },
};
</script>
