<template>
  <v-container fluid>
    <v-card class="px-6 py-6 flex-grow-1">
      <v-data-table  :search="search" :loading="loading" :headers="headers" :items="award">


        <template v-slot:top>
          <v-toolbar class="mb-4" flat>
            <v-toolbar-title class="text-h4">Awards</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2 mr-2" large @click="reorderItems">Reorder</v-btn>

            <v-btn color="primary" dark class="mb-2" large @click="addAward()">Add Award</v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" color="primary"  @click="editAward(item)" medium>mdi-pencil</v-icon>
          <!-- <v-icon color="red" @click="deleteAward(item.awardID)" medium>mdi-delete</v-icon>
          add when route is created -->
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-chip
          small
           text-color="white"
           class="ma-0"
           :color="item.active == true ?  'green' : '' ||  item.active == false ?  'red' : ''"
          >
           {{item.active == true ?  'Active' : '' || item.active == false ?  'Disabled' : ''}}
          </v-chip>
        </template>

        <template v-slot:[`item.displayOnKiosk`]="{ item }">
          <v-chip
          small
           text-color="white"
           class="ma-0"
           :color="item.displayOnKiosk == true ?  'green' : '' ||  item.displayOnKiosk == false ?  'red' : ''"
          >
           {{item.displayOnKiosk == true ?  'Visible' : '' || item.displayOnKiosk == false ?  'Hidden' : ''}}
          </v-chip>
        </template>

      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogReorder" width="unset">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          Reorder Awards
          <v-icon large class="white--text ml-4">mdi-trophy</v-icon>
        </v-card-title>
        <v-container style="max-height: 180px;overflow: auto;">
          <draggable v-model="award" @start="drag=true" @end="drag=false, dragEnd()">
            <tr v-for="(item, index) in award" :key="index">
              <v-icon small class="page__grab-icon mr-5">
                mdi-arrow-all
              </v-icon>
              <td>{{item.description}}</td>
            </tr>
          </draggable>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogReorder=false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          {{formTitle}}
          <v-icon large class="white--text ml-4">mdi-gift</v-icon>
        </v-card-title>
        <v-container class="px-8">
          <v-card-text>
            <v-row>

              <v-col class="py-2" cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.description"
                  auto-grow
                  rows="1"
                  label="Description"
                >
                    <v-icon slot="prepend">mdi-text</v-icon>
                </v-textarea>
              </v-col>

              <v-col class="py-2" cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.kioskDescription"
                  auto-grow
                  rows="1"
                  label="Kiosk Description"
                >
                    <v-icon slot="prepend">mdi-text</v-icon>
                </v-textarea>
              </v-col>

              <v-col class="py-2" cols="4" sm="4" md="4">
                Status
                <v-switch
                  v-model="editedItem.active"
                  :label="editedItem.active ? 'Active' : 'Disabled'"
                  color="success"
                  class="mt-1"
                  hide-details
                ></v-switch>
              </v-col>

              <v-col class="py-2" cols="4" sm="4" md="4">
                Display on Kiosk
                <v-switch
                  v-model="editedItem.displayOnKiosk"
                  :label="editedItem.displayOnKiosk ? 'Visible' : 'Hidden'"
                  color="success"
                  class="mt-1"
                  hide-details
                ></v-switch>
              </v-col>

              <v-col class="py-2" cols="4" sm="4">
                <v-text-field
                  v-model="editedItem.value"
                  label="Cost (points)"
                  type="number"
                >
                  <v-icon slot="prepend">mdi-star</v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-color-picker
                v-model="editedItem.colorCode"
                hide-canvas
                hide-inputs
                hide-sliders
                mode="hexa"
                show-swatches
                style="max-width: none;"
              ></v-color-picker>
            </v-row> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text large @click="dialog = false">Cancel</v-btn>
            <v-btn class="primary" large @click="saveAward()">Save</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
            Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
import Draggable from 'vuedraggable';

// import awards from '../../data/award.json'
export default {
  components:{
    Draggable,
  },
  data() {
    return {
      award: [],
      loading: true,
      dialog: false,
      editedIndex: -1,
      search: '',
      snackbarVisible: false,
      dialogReorder: false,

      headers: [
        {
          text: 'Description',
          align: 'start',
          value: 'description'
        },
        {
          text: 'Kiosk Description',
          align: 'start',
          value: 'kioskDescription',
          width:'300px',
        },
        { text: 'Status', value: 'active', align: "end" },
        { text: 'Display on Kiosk', value: 'displayOnKiosk', align: "end" },
        { text: 'Value', filterable: false, value: 'value', align: "end" },
        { text: 'Action', cellClass: "py-2", value: 'actions', sortable: false, width:"100px", align:"center" }
      ],
      editedItem: {
        description: "",
        value: Number,
        active: false,
        displayOnKiosk: false,
        kioskDescription: "",
        awardId: Number,
        colorCode: "#000",
        staffAward: true,
      },
    }
  },
  async mounted() {
    await this.retrieveAwards()
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Award Item' : 'Edit Award Item'
    }
  },
  methods: {
    dragEnd(){
      this.award.forEach((element)=>{
        element.selfOrder = this.award.indexOf(element);
        console.log(element)
        this.$api.award.editOne(this.$siteKey, element)
        //this.$api.incentive.updateOne(this.$siteKey, element)
      })
    },
    reorderItems(){
      this.dialogReorder = true;
      this.reset();
    },
    reset() {
      this.editedItem = {
        description: "",
        value: Number,
        active: false,
        displayOnKiosk: false,
        kioskDescription: "",
        awardId: Number,
        colorCode: "#000",
        staffAward: true,
      }
    },
    async retrieveAwards() {
      try {
        var response = await this.$api.award.getAllStaff(this.$siteKey)
        this.award = response.data.filter(element=>element.staffAward==true)
        this.loading = false
        this.snackbarText = 'Awards loaded.'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (err) {
        this.snackbarText = 'Failed to load awards'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(err)
      }
    },
    addAward() {
      this.reset()
      this.dialog = true
    },
    async editAward(item) {
      this.editedIndex = this.award.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = !this.dialog
    },
    async deleteAward(awardID) {
      try {
        var response = await this.$api.award.deleteOne(this.$siteKey, awardID)
        this.award = response.data
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    close () {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
        this.retrieveAwards()
      })
      this.dialog = false
    },
    async saveAward () {
      let data = this.editedItem
      if (this.editedItem.colorCode){
        if (this.editedItem.colorCode.hex){
          data.colorCode = this.editedItem.colorCode.hex
        }
      }
      data.staffAward = true;
      if(this.editedIndex === -1)
        try {
          console.log(data)
          await this.$api.award.createOne(this.$siteKey, data)
          this.loading = false
          this.snackbarText = 'Award added.'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          this.close()
        } catch (err) {
          this.snackbarText = 'Failed to add award'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(err)
        }
      else {
        try {
        await this.$api.award.editOne(this.$siteKey, data)
          this.loading = false
          this.snackbarText = 'Award updated.'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          this.close()
        } catch (err) {
          this.snackbarText = 'Failed to update award.'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(err)
        }
      }
    }
  }
}
</script>