import Vue from 'vue'
import VueRouter from 'vue-router'

import Container from '@/views/Container.vue'

import Dashboard from '@/views/Dashboard'
import Members from '@/views/Members'
import Noticeboard from '@/views/Noticeboard'

import AccountsPrisoner from '@/views/Prisoners/PrisonerAccounts'
import AwardsPrisoner from '@/views/Prisoners/PrisonerAwards'
import IncentivePrisoner from '@/views/Prisoners/PrisonerIncentives'
import PointsPrisoner from '@/views/Prisoners/PrisonerPoints'
import SuspensionsPrisoner from '@/views/Prisoners/PrisonerSuspensions'
import TransactionsPrisoner from '@/views/Prisoners/PrisonerTransactions'

import AccountsStaff from '@/views/Staff/StaffAccounts'
import AwardsStaff from '@/views/Staff/StaffAwards'
import IncentiveStaff from '@/views/Staff/StaffIncentives'
import PointsStaff from '@/views/Staff/StaffPoints'
import TransactionsStaff from '@/views/Staff/StaffTransactions'

import Settings from '@/views/Settings'
import Documents from '@/views/Documents'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes: [
    {
      name: 'Redirect',
      path: '/',
      component: {
        render: h => h('div'),
        mounted() {
            window.location = `https://launcher.unify.ws?returnUrl=${window.location.origin}`
        },
      },
      meta: { requiresAuth: true },
    },
    {
      path: '/:buildKey',
      meta: { requiresAuth: true },
      component: Container,
      children: [
        { name: 'Dashboard', path: '', component: Dashboard, meta: { requiresAuth: true }},
        { name: 'Members', path: 'Members', component: Members, meta: { requiresAuth: true, props:true }},
        { name: 'Noticeboard', path: 'Noticeboard', component: Noticeboard, meta: { requiresAuth: true, props:true }},

        { name: 'PointsPrisoner', path: 'PointsPrisoner', component: PointsPrisoner, props: true, meta: { requiresAuth: true }},
        { name: 'AwardsPrisoner', path: 'AwardsPrisoner', component: AwardsPrisoner, meta: { requiresAuth: true }},
        { name: 'TransactionsPrisoner', path: 'TransactionsPrisoner', component: TransactionsPrisoner, meta: { requiresAuth: true }},
        { name: 'AccountsPrisoner', path: 'AccountsPrisoner', component: AccountsPrisoner, meta: { requiresAuth: true }},
        { name: 'IncentivesPrisoner', path: 'IncentivesPrisoner', component: IncentivePrisoner, meta: { requiresAuth: true }},
        { name: 'SuspensionsPrisoner', path: 'SuspensionsPrisoner', component: SuspensionsPrisoner, meta: { requiresAuth: true }},

        { name: 'PointsStaff', path: 'PointsStaff', component: PointsStaff, props: true, meta: { requiresAuth: true }},
        { name: 'AwardsStaff', path: 'AwardsStaff', component: AwardsStaff, meta: { requiresAuth: true }},
        { name: 'TransactionsStaff', path: 'TransactionsStaff', component: TransactionsStaff, meta: { requiresAuth: true }},
        { name: 'AccountsStaff', path: 'AccountsStaff', component: AccountsStaff, meta: { requiresAuth: true }},
        { name: 'IncentivesStaff', path: 'IncentivesStaff', component: IncentiveStaff, meta: { requiresAuth: true }},

        { name: 'Settings', path: 'Settings', component: Settings, meta: { requiresAuth: true }},
        { name: 'Documents', path: 'Documents', component: Documents, meta: { requiresAuth: true }}
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // from (what buildKey are you leaving?)
  // to (what buildKey are you going to?)
  //console.log(to.params.buildKey)
  Vue.prototype.$siteKey = to.params.buildKey;
  // if (to.params.buildKey && to.params.buildKey != from.params.buildKey) {
  //   try {
  //     let response = await Vue.prototype.$api.builds.getOne(to.params.buildKey)
  //     store.commit('setBuild', response.data)
  //     response = await Vue.prototype.$api.roles.getUserRole(to.params.buildKey)
  //     Vue.prototype.$acl.set(response.data.permissions)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // } 
  // else if (!to.params.buildKey) {
  //   store.commit('setBuild', {})
  // }
  next()
})

export default router
