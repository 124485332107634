<template>
  <v-container fluid>
    <v-card class="px-6 py-6">
      <v-data-table
        :headers="headers"
        :sort-by="['timestamp']"
        :sort-desc="[true]"
        :items="transactions"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :loading="transactionsLoading"
        loading-text="Loading transactions - please wait"
      >
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ transactionTimestamp(item.timestamp) }}
        </template>
        <template v-slot:[`item.status`]="{ item }" >
          <v-edit-dialog
            :return-value.sync="item.status"
            @save="
              item.status == 2
                ? confirmUpdateTransaction(item)
                : updateTransaction(item)
            "
            persistent
            large
          >
            <v-chip
              small
              class="ma-2"
              text-color="white"
              :color="
                item.status == 0
                  ? 'green'
                  : '' || item.status == 1
                  ? 'orange'
                  : '' || item.status == 2
                  ? 'red'
                  : '' || item.status == 3
                  ? 'cyan'
                  : ''
              "
              @click="tempStatus = item.status"
            >
              {{
                item.status == 0
                  ? "Complete"
                  : "" || item.status == 1
                  ? "Pending"
                  : "" || item.status == 2
                  ? "Declined"
                  : "" || item.status == 3
                  ? 'Actioned'
                  : ''
              }}
            </v-chip>
            <template v-slot:input>
              <div class="py-6">
                <p class="mb-6 text-h6" v-if="item.status == 2 ? true : false">
                  Transaction status can't<br />be updated from declined.
                </p>
                <v-select
                  v-model="tempStatus"
                  item-text="text"
                  item-value="value"
                  :disabled="item.status == 2 ? true : false"
                  :items="statusItems"
                  label="Status"
                  class="py-0"
                >
                  <template #selection="{ item }">
                    <v-chip
                      small
                      class="ma-2"
                      text-color="white"
                      :color="
                        item.value == 0
                          ? 'green'
                          : '' || item.value == 1
                          ? 'orange'
                          : '' || item.value == 2
                          ? 'red'
                          : '' || item.value == 3
                          ? 'cyan'
                          : ''
                      "
                    >
                      {{
                        item.value == 0
                          ? "Completed"
                          : "" || item.value == 1
                          ? "Pending"
                          : "" || item.value == 2
                          ? "Declined"
                          : "" || item.value == 3
                          ? 'Actioned'
                          : ''
                      }}
                    </v-chip>
                  </template>
                </v-select>

                <v-text-field
                  v-model="item.awardReason"
                  single-line
                  hide-details
                  label="Reason"
                  class="py-0"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.transactionMonetaryValue`]="{ item }">
          <span v-if="item.transactionAmount < 0">{{
            item.transactionMonetaryValue.toFixed(2)
          }}</span>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-simple-checkbox
            color="primary"
            v-model="item.active"
          ></v-simple-checkbox>
        </template>
        <template v-slot:top>
          <v-row>
            <v-toolbar-title class="text-h4">Staff Transactions</v-toolbar-title>
          </v-row>
          <v-row class="mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @click:append="fetchTransactions"
            ></v-text-field>
            <v-dialog
              ref="startDialog"
              v-model="startModal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="startModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="endDialog"
              v-model="endModal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="endModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-row class="mb-2">
              <v-checkbox
                v-model="filterSuspensionsCheck"
                label="Hide suspensions"
                hide-details
                class="px-2"
              ></v-checkbox>
              <v-checkbox
                v-model="filterCompletedStatus"
                label="Hide completed"
                hide-details
                class="px-2"
              ></v-checkbox>
            </v-row>
            <div>
              <v-btn class="primary" @click="getExportData" v-if="!exportProcessing">Export</v-btn>
              <v-progress-circular
                v-else
                :size="50"
                :width="7"
                color="impbblue"
                indeterminate
              ></v-progress-circular>

            </div>
            <!-- <v-btn color="primary" dark class="mb-2" large  @click="dialog = !dialog">Add Transaction</v-btn> -->
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="accent"
            v-if="item.isRefundable == true"
            @click="undoTransaction(item)"
            >mdi-arrow-u-left-top-bold</v-icon
          >
          <v-icon
            v-if="item.transactionAmount<0 && !receiptsLoading"
            color="green"
            @click="discoverUserTransactions(item.staffAccount), selectedTransaction = item"
          >
            mdi-receipt-send
          </v-icon>
        </template>
      </v-data-table>
      <v-row class="text-center px-4 pt-4 align-center justify-center" wrap>
        <v-col cols="12" md="6">
            <v-pagination
                v-model="page"
                :length="pageCount">
            </v-pagination>
        </v-col>
      </v-row>
      <v-row class="text-center px-4 align-center justify-center" wrap>
        <v-col cols="6" md="3">
          <v-select
              dense
              outlined
              hide-details
              :value="itemsPerPage"
              label="Items per page"
              @change="itemsPerPage = parseInt($event, 10), fetchTransactions()"
              :items="perPageChoices">
          </v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="confirmDeleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to mark this transaction as declined?
        </v-card-title>
        <v-card-text
          >Transactions marked as declined cannot be reverted.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(confirmDeleteDialog = false), fetchTransactions()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="accent"
            text
            @click="updateTransaction(editedTransaction)"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="receiptCheckDialog" persistent max-width="1000">
      <v-card v-if="selectedTransaction">
        <v-card-title class="text-h6">
          Transaction Receipts for {{selectedTransaction.staffAccount.name}}
        </v-card-title>
        <v-card-text v-if="selectedTransaction.receiptCreated">A receipt has previously been generated for this transaction.</v-card-text>
        <v-card-text v-else>No receipt has been generated for this transaction.</v-card-text>
        <v-card-text v-if="transactionsAwaitingReceipts.length>0">This staff member has other transactions without receipts.</v-card-text>
        <v-card-actions>
          <v-btn text @click="(receiptCheckDialog = false, receiptsLoading = false)"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            v-if="transactionsAwaitingReceipts.length>0"
            @click="submitMultiplePDFs(true), receiptCheckDialog = false"
          >
            Receipt for all
          </v-btn>
          <v-btn
            text
            @click="submitPDF(), receiptCheckDialog = false"
          >
            Receipt for this transaction only
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmUndoDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h6">
          Refund just this transaction or every transaction in the same batch?
        </v-card-title>
        <v-card-text>Refunded transactions cannot be reverted.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="(confirmUndoDialog = false), fetchTransactions()"
            >Cancel</v-btn
          >
          <v-btn
            class="accent"
            text
            @click="refundBatchTransactions(editedTransaction)"
            :loading="refundingBatch"
          >
            Refund All
          </v-btn>
          <v-btn
            class="accent"
            text
            @click="refundTransaction(editedTransaction)"
          >
            Refund One
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-if="snackbarVisible === true"
      v-model="snackbarVisible"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      tempStatus: null,
      transactions: [],
      loadingTable: true,
      filterCallTransactionsCheck: false,
      filterSuspensionsCheck: false,
      filterCompletedStatus: false,
      search: "",
      snackbarVisible: false,
      date: "",
      startModal: false,
      endModal: false,
      page: 1,
      itemsPerPage: 10,
      receiptCheckDialog: false,
      receiptsLoading: false,
      perPageChoices: [
          {text:'5 records/page' , value: 5},
          {text:'10 records/page' , value: 10},
          {text:'20 records/page' , value: 20},
      ],
      pageCount: 0,
      exportProcessing: false,
      selectedTransaction: null,
      headers: [
        { text: "Date", align: "start", sortable: true, value: "timestamp" },
        { text: "Name", value: "staffAccount.name" },
        { text: "Urn", value: "staffAccount.urn" },
        { text: "Description", value: "description" },
        // {text: 'Staff', value: 'staff' },
        { text: "Reason", value: "awardReason" },
        { text: "Status", value: "status", align: "end" },
        {
          text: "Cost (£)",
          value: "transactionMonetaryValue",
          align: "end",
        },
        {
          text: "Amount (points)",
          value: "transactionAmount",
          align: "end",
        },
        { text: "Balance", value: "accountBalance", align: "end" },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          align: "end",
          width: "100px",
        },
      ],
      statusItems: [
        { text: "Completed", value: 0 },
        { text: "Pending", value: 1 },
        { text: "Actioned", value: 3},
        { text: "Declined", value: 2 },

      ],
      editedTransaction: null,
      confirmDeleteDialog: false,
      confirmUndoDialog: false,
      startDate: null,
      endDate: null,
      transactionsLoading: false,
      refundingBatch: false,
    };
  },
  async mounted() {
    await this.fetchTransactions();
  },
  watch: {
    startDate: function() {
      this.fetchTransactions()
      this.startModal = false
      this.endModal = false
    },
    endDate: function() {
      this.fetchTransactions()
      this.startModal = false
      this.endModal = false
    },
    page: function(){
      this.fetchTransactions();
    },
    filterCompletedStatus(){
      this.fetchTransactions();
    },
    filterSuspensionsCheck(){
      this.fetchTransactions()
    },
    filterCallTransactionsCheck(){
      this.fetchTransactions()
    },
    // search(){
    //   this.fetchTransactions();
    // }
  },
  methods: {
    objectToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
          const values = headers.map(header => {
              const val = row[header]
              return `"${val}"`;
          });
          csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    },
    async discoverUserTransactions(account){
      this.receiptsLoading = true;
      var response = await this.$api.transaction.getTransactionsNoReceiptStaff(this.$siteKey, account.staffAccountID)
      this.transactionsAwaitingReceipts = response.data
      this.transactionsAwaitingReceipts.forEach((element)=>{
        if (element.transactionID == this.selectedTransaction.transactionID){
          this.transactionsAwaitingReceipts.splice(this.transactionsAwaitingReceipts.indexOf(element), 1);
        }
      })
      this.receiptCheckDialog = true
    },
    async submitMultiplePDFs(selected = false){
      if (selected){
        this.transactionsAwaitingReceipts.push(this.selectedTransaction)
      }
      var siteResponse = await this.$api.site.getOne(this.$siteKey)
      var site = siteResponse.data
      if (site.receiptFileName) {
        var pdfObject = {
          descriptions: [],
          account: {
            name: this.transactionsAwaitingReceipts[0].staffAccount.name,
            sentenceNumber: this.transactionsAwaitingReceipts[0].staffAccount.urn,
            location: ""
          }, 
        }
        var maxIteration = 10
        for (var i = 0; i < maxIteration; i++){
          pdfObject.descriptions.push(i < this.transactionsAwaitingReceipts.length ? this.transactionsAwaitingReceipts[i].description : "")
          if (i < this.transactionsAwaitingReceipts.length) {
            this.transactionsAwaitingReceipts[i].receiptCreated = true;
            await this.$api.transaction.updateOne(this.$siteKey, this.transactionsAwaitingReceipts[i])
          }
        }
        var returnDictionary = await this.createPDFTemplate(site.receiptFileName, pdfObject)
        var response = await this.$api.pdf.createFinishedPDF(this.$siteKey, site.receiptFileName, returnDictionary);
        var downloadPDF = new Blob([this.base64ToArrayBuffer(response.data.pdfFile)], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = URL.createObjectURL(downloadPDF);
        link.download = `${this.selectedTransaction.staffAccount.name} - ${this.selectedTransaction.description}.pdf`.toLowerCase();
        link.click();
      }
      this.receiptsLoading = false;  
    },
    async submitPDF(){
      var siteResponse = await this.$api.site.getOne(this.$siteKey)
      var site = siteResponse.data
      if (site.receiptFileName) {
        this.selectedTransaction.descriptions = [this.selectedTransaction.description,"","","","","","","","",""]
        var returnDictionary = await this.createPDFTemplate(site.receiptFileName, this.selectedTransaction)
        var response = await this.$api.pdf.createFinishedPDF(this.$siteKey, site.receiptFileName, returnDictionary);
        var downloadPDF = new Blob([this.base64ToArrayBuffer(response.data.pdfFile)], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = URL.createObjectURL(downloadPDF);
        link.download = `${this.selectedTransaction.staffAccount.name} - ${this.selectedTransaction.description}.pdf`.toLowerCase();
        link.click();
        this.selectedTransaction.receiptCreated = true
        await this.$api.transaction.updateOne(this.$siteKey, this.selectedTransaction)
        //await this.fetchTransactions()
      }
      this.receiptsLoading = false;
    },
    async createPDFTemplate(filename, transactionDetails){
      var temp = filename.split(".")[0]
      var response = await this.$api.pdf.getPDFTypes(this.$siteKey, temp);
      var returnDictionary = Object.fromEntries(Object.keys(response.data.fieldDict).map((c) => [c, null]));
      console.log(transactionDetails)
      this.informationDataType = response.data.fieldDict;
      if ("<PrisonerName>" in returnDictionary){
        returnDictionary["<PrisonerName>"] = transactionDetails.staffAccount.name
      }
      if ("<Name>" in returnDictionary){
        returnDictionary["<Name>"] = transactionDetails.account.name
      }
      if ("<PrisonerNumber>" in returnDictionary){
        returnDictionary["<PrisonerNumber>"] = transactionDetails.staffAccount.urn
      }
      if ("<PrisonerLocation>" in returnDictionary) {
        returnDictionary["<PrisonerLocation>"] = ""
      }
      if ("<Date>" in returnDictionary) {
        var date = new Date()
        returnDictionary["<Date>"] = date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()
      }
      for (let i = 1; i <= 10; i++) {
        if ("<IncentiveCode"+i+">" in returnDictionary){
          returnDictionary["<IncentiveCode"+i+">"] = transactionDetails.descriptions[i-1];
        }
      }
      if ("<Item>" in returnDictionary){
        returnDictionary["<Item>"] = transactionDetails.description;
      } 
      return returnDictionary
    },
    base64ToArrayBuffer(base64) {
      var binaryString = atob(base64);
      var bytes = new Uint8Array(binaryString.length);
      for (var i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    },
    async getExportData(){
      this.exportProcessing = true;
      var response = await this.$api.transaction.getTransactionsByDateRange(
        this.$siteKey, 
        this.startDate === null ? new Date(2022,0,1).toISOString() : this.startDate,
        this.endDate === null ? new Date().toISOString() : this.endDate + " 23:59:59",
        {
          Staff: true,
          Count: false,
          Export: true,
          NumberOfItems: this.itemsPerPage,
          CurrentPage: this.page,
          HideSuspensions: this.filterSuspensionsCheck,
          HideCompleted: this.filterCompletedStatus,
          HidePoints: this.filterCallTransactionsCheck,
          Search: this.search
        }
      )
      let csvContent = "data:text/csv;charset=utf-8," + this.objectToCsv(response.data);
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'CRIS_Transactions_Export_' + this.getCurrentDate());
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      this.exportProcessing = false
    },
    async getPageCount() {    
      var length = 0;  
      await this.$api.transaction.getTransactionsByDateRange(
          this.$siteKey, 
          this.startDate === null ? new Date(2022,0,1).toISOString() : this.startDate,
          this.endDate === null ? new Date().toISOString() : this.endDate + " 23:59:59",
          {
            Staff: true,
            Count: true,
            Export: false,
            NumberOfItems: this.itemsPerPage,
            CurrentPage: this.page,
            HideSuspensions: this.filterSuspensionsCheck,
            HideCompleted: this.filterCompletedStatus,
            HidePoints: this.filterCallTransactionsCheck,
            Search: this.search
          }
        ).then((response)=>{
          length = response.data;
        });
      this.pageCount = Math.floor((length / this.itemsPerPage) + 1)
    },
    async fetchTransactions() {
      try {
        this.transactions = []
        this.transactionsLoading = true
        await this.getPageCount();
        let response;
        response = await this.$api.transaction.getTransactionsByDateRange(
          this.$siteKey, 
          this.startDate === null ? new Date(2022,0,1).toISOString() : this.startDate,
          this.endDate === null ? new Date().toISOString() : this.endDate + " 23:59:59",
          {
            Staff: true,
            Count: false,
            Export: false,
            NumberOfItems: this.itemsPerPage,
            CurrentPage: this.page,
            HideSuspensions: this.filterSuspensionsCheck,
            HideCompleted: this.filterCompletedStatus,
            HidePoints: this.filterCallTransactionsCheck,
            Search: this.search
          }
        );
        
        this.transactions = response.data;
        this.snackbarText = "Transactions loaded.";
        this.snackbarColor = "primary";
        this.loadingTable = false;
      } catch (err) {
        this.snackbarText = "Unable to load transactions.";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
      this.transactionsLoading = false
    },
    onDateRangeSelected(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.fetchTransactions();
    },
    getCurrentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },
    transactionTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    confirmUpdateTransaction(item) {
      this.editedTransaction = item;
      this.confirmDeleteDialog = true;
    },
    undoTransaction(transaction) {
      this.confirmUndoDialog = true;
      this.editedTransaction = transaction;
    },
    async refundBatchTransactions(transaction){
      this.refundingBatch = true;
      try{
        var response = await this.$api.transaction.refundStaffBatch(this.$siteKey, transaction)
        this.snackbarText = `${response.data.SuccessfulCount} out of ${response.data.TotalCount} Transaction(s) refunded`
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
        this.loadingTable = false
        this.confirmUndoDialog = false
        this.fetchTransactions()
      }catch (err) {
        this.snackbarText = "Failed to refund transactions";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
      this.refundingBatch = false;
    },
    async refundTransaction(transaction) {
      try {
          await this.$api.transaction.refundOneStaff(this.$siteKey, transaction)
          this.snackbarText = 'Transaction refunded'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          this.loadingTable = false
          this.confirmUndoDialog = false
          await this.fetchTransactions()
      } catch (err) {
        this.snackbarText = "Failed to refund transaction";
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    async updateTransaction(editedTransaction) {
      editedTransaction.status = this.tempStatus;
      try {
          await this.$api.transaction.updateOne(this.$siteKey, editedTransaction)
          this.snackbarText = 'Transaction status updated to ' + editedTransaction.status
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          this.loadingTable = false
          this.confirmDeleteDialog = false
          await this.fetchTransactions()
      } catch (err) {
        this.snackbarText =
          "Failed to update transaction status to " + editedTransaction.status;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        console.log(err);
      }
    },
    datesChanged() {
      this.fetchTransactions()
      this.startModal = false
      this.endModal = false
    },
  },
};
</script>
