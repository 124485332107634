<template>
  <v-container fluid>
    <v-card class="px-6 py-6 flex-grow-1">
      <v-data-table  :search="search" :loading="loading" :headers="headers" :items="suspension">


        <template v-slot:top>
          <v-toolbar class="mb-4" flat>
            <v-toolbar-title class="text-h4">Suspensions/Withdrawals</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-btn color="primary" dark class="mb-2" large @click="addSuspension()">Add Suspension</v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" color="primary"  @click="editSuspension(item)" medium>mdi-pencil</v-icon>
          <!-- <v-icon color="red" @click="deleteAward(item.awardID)" medium>mdi-delete</v-icon>
          add when route is created -->
        </template>

        <template v-slot:[`item.type`]="{ item }">
          <v-chip
            small
            text-color="white"
            class="ma-0"
            :color="item.type == 'lift' ?  'green' : '' ||  item.type == 'suspension' ?  'red' : '' || item.type == 'withdraw' ? 'orange' : ''"
          >
           {{item.type == 'lift' ?  'Un-suspension' : '' || item.type == 'suspension' ?  'Suspension' : '' || item.type == 'withdraw' ? 'Withdrawal' : ''}}
          </v-chip>
        </template>

      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
          {{formTitle}}
          <v-icon large class="white--text ml-4">mdi-gift</v-icon>
        </v-card-title>
        <v-container class="px-8">
          <v-card-text>
            <v-row>
              <v-col class="py-2" cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.reason"
                  auto-grow
                  rows="1"
                  label="Reason"
                >
                    <v-icon slot="prepend">mdi-text</v-icon>
                </v-textarea>
              </v-col>

              <v-col cols="4" class="w-100 pb-0 d-flex justify-center text-center">
                <v-radio-group col class="py-0 mt-0 flex-grow-1 text-center justify-center d-block"
                  v-model="editedItem.type">
                  <v-radio label="Suspension" true-value="suspension" value="suspension"></v-radio>
                  <v-radio label="Un-suspension" true-value="lift" value="lift"></v-radio>
                  <v-radio label="Withdrawal" value="withdraw" true-value="withdraw"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col class="py-2" cols="4" sm="4" v-if="editedItem.type == 'suspension'">
                <v-text-field
                  v-model="editedItem.duration"
                  label="Duration (days)"
                  type="number"
                >
                  <v-icon slot="prepend">mdi-star</v-icon>
                </v-text-field>
              </v-col>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text large @click="dialog = false">Cancel</v-btn>
            <v-btn class="primary" large @click="saveSuspension()">Save</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
            Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
// import awards from '../../data/award.json'
export default {
  data() {
    return {
      suspension: [],
      loading: true,
      dialog: false,
      editedIndex: -1,
      search: '',
      snackbarVisible: false,
      headers: [
        {
          text: 'Reason',
          align: 'start',
          value: 'reason'
        },
        { text: 'Duration (days)', filterable: false, value: 'duration', align: "end" },
        { text: 'Type', value: 'type', align: "end" },
        { text: 'Action', cellClass: "py-2", value: 'actions', sortable: false, width:"100px", align:"center" }
      ],
      editedItem: {
        reason: "",
        duration: Number,
        SuspensionId: Number
      }
    }
  },
  async mounted() {
    await this.retrieveSuspensions()
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Suspension Item' : 'Edit Suspension Item'
    }
  },
  methods: {
    reset() {
      this.editedItem = {
        reason: "",
        duration: Number,
        suspensionId: Number
      }
    },
    async retrieveSuspensions() {
      try {
        var response = await this.$api.suspension.getAll(this.$siteKey)
        this.suspension = response.data
        this.loading = false
        this.snackbarText = 'Suspensions loaded.'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (err) {
        this.snackbarText = 'Failed to load suspensions'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(err)
      }
    },
    addSuspension() {
      this.reset()
      this.dialog = true
    },
    async editSuspension(item) {
      this.editedIndex = this.suspension.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = !this.dialog
    },
    async deleteSuspension(suspensionID) {
      try {
        var response = await this.$api.suspension.deleteOne(this.$siteKey, suspensionID)
        this.suspension = response.data
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    close () {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
        this.retrieveSuspensions()
      })
      this.dialog = false
    },
    async saveSuspension () {
      let data = this.editedItem
      if(this.editedIndex === -1)
        try {
          await this.$api.suspension.createOne(this.$siteKey, data)
          this.loading = false
          this.snackbarText = 'Suspension added.'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          this.close()
        } catch (err) {
          this.snackbarText = 'Failed to add suspension'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(err)
        }
      else {
        try {
        await this.$api.suspension.editOne(this.$siteKey, data)
          this.loading = false
          this.snackbarText = 'Suspension updated.'
          this.snackbarColor = 'primary'
          this.snackbarVisible = true
          this.close()
        } catch (err) {
          this.snackbarText = 'Failed to update suspension.'
          this.snackbarColor = 'red'
          this.snackbarVisible = true
          console.log(err)
        }
      }
    }
  }
}
</script>