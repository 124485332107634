<template>
  <v-container fluid>
    <v-row>
      <v-col class="py-0 my-0" cols="12" md="12" sm="12">
        <h2>Suspensions Summary</h2>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-card class="py-2 px-2" min-height="250">
          <v-card-title class="justify-center">
            <v-col>
              <h3 class="text-center mb-2">{{ pieChartMonthVisible ? selectedMonth: chartTypeName[1] }}</h3>
              <v-row style="justify-content: center; align-items: center">
                <v-btn icon :style="displayYear>2023?'':'visibility:hidden'" @click="displayYear-=1, buildSuspensionSummary('month')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <small class="text-center" style="display:flex !important">{{displayYear}}</small>
                <v-btn icon :style="displayYear<currentYear?'':'visibility:hidden'" @click="displayYear+=1, buildSuspensionSummary('month')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-card-title>
          <div style="position: absolute; top: 45%; left: 48%;" v-if="chartMonthLoading">
            <v-progress-circular
              :size="50"
              :width="7"
              color="impbblue"
              indeterminate
            ></v-progress-circular>
          </div>
          <BarChart 
            v-else-if="!pieChartMonthVisible" 
            :chartLabels="chartMonths" :chartDataSets="monthData"
            v-on:onSelect="barChartMonthClick($event)"
          />
          <div v-else>
            <v-icon 
              @click="pieChartMonthVisible=false"
              style="position:absolute"
            >
              mdi-keyboard-return
            </v-icon>
            <PieChart :chartLabels="pieChartMonthLabels" :chartDataSets="pieChartMonthData" :chartColors="pieChartColors"/>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card class="py-2 px-2" min-height="250">
          <h3 class="text-center">{{ pieChartWeekVisible ? selectedWeek : chartTypeName[0] }}</h3>
          <div style="position: absolute; top: 45%; left: 48%;" v-if="chartWeekLoading">
            <v-progress-circular
              :size="50"
              :width="7"
              color="impbblue"
              indeterminate
            ></v-progress-circular>
          </div>
          <BarChart 
            v-else-if="!pieChartWeekVisible"
            :chartLabels="chartWeekDays" :chartDataSets="weekData"
            v-on:onSelect="barChartWeekClick($event)"
          />
          <div v-else>
            <v-icon 
              @click="pieChartWeekVisible=false"
              style="position:absolute"
            >
              mdi-keyboard-return
            </v-icon>
            <PieChart :chartLabels="pieChartWeekLabels" :chartDataSets="pieChartWeekData" :chartColors="pieChartColors"/>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card class="py-2 px-2" min-height="250">
          <h3 class="text-center">{{ pieChartYearVisible ? selectedYear : chartTypeName[2] }}</h3>
          <div style="position: absolute; top: 45%; left: 48%;" v-if="chartYearLoading">
            <v-progress-circular
              :size="50"
              :width="7"
              color="impbblue"
              indeterminate
            ></v-progress-circular>
          </div>
          <BarChart 
            v-else-if="!pieChartYearVisible"
            :chartLabels="chartYears" :chartDataSets="yearData" 
            v-on:onSelect="barChartYearClick($event)"
          />
          <div v-else>
            <v-icon 
              @click="pieChartYearVisible=false"
              style="position:absolute"
            >
              mdi-keyboard-return
            </v-icon>
            <PieChart :chartLabels="pieChartYearLabels" :chartDataSets="pieChartYearData" :chartColors="pieChartColors"/>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-if="snackbarVisible === true" v-model="snackbarVisible" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BarChart from "../components/Bar";
import PieChart from "../components/Pie"
import moment from "moment";

export default {
  data() {
    return {
      months:[ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ],
      chartTypeName: ["Weekly", "Monthly", "Yearly"],
      labels: ["Suspended", "Resumed"],
      chartFourWeeks: [],
      chartMonths: [],
      chartYears: [],
      chartWeekDays: [],
      chartDataSets: [],
      snackbarVisible: false,

      selectedMonth: null,
      selectedYear: null,
      selectedWeek: null,

      pieChartMonthLabels: [],
      pieChartMonthData: [],
      pieChartMonthVisible: false,
      pieChartWeekLabels: [],
      pieChartWeekData: [],
      pieChartWeekVisible: false,
      pieChartYearLabels: [],
      pieChartYearData: [],
      pieChartYearVisible: false,
      pieChartColors: [],

      chartMonthLoading: true,
      chartYearLoading: true,
      chartWeekLoading: true,
      monthData: [],
      yearData: [],
      weekData: [],

      displayYear: moment().year(),
      currentYear: moment().year(),
    };
  },
  components: {
    BarChart,
    PieChart,
  },
  created() {
    this.getFourWeeks();
    this.getMonths();
    this.getYears();
    this.setWeekDates();
  },
  mounted(){
    this.buildSuspensionSummary("month") 
    this.buildSuspensionSummary("year") 
    this.buildSuspensionSummary("week")
  },
  methods: {
    async createDataPoints(data){
      var dataSets = [
        {
          label: this.labels[0],
          backgroundColor: "#4f5d67",
          data: data.Suspended,
        },
        {
          label: this.labels[1],
          backgroundColor: "#ee2a24c2",
          data: data.Continued,
        },
      ];
      return dataSets;
    },
    async buildSuspensionSummary(type){
      var response = await this.$api.transaction.getSuspensionSummary(this.$siteKey, type);
      if (type == "month"){
        this.monthData = await this.createDataPoints(response.data[this.displayYear])
        this.chartMonthLoading = false;
      }
      else if (type == "year"){
        response.data.Continued.length = response.data.Suspended.length = 5
        this.yearData = await this.createDataPoints(response.data)
        this.chartYearLoading = false;
      }
      else if (type == "week"){
        response.data.Continued.length = response.data.Suspended.length = 4
        response.data.Continued = response.data.Continued.reverse()
        response.data.Suspended = response.data.Suspended.reverse()
        this.weekData = await this.createDataPoints(response.data);
        this.chartWeekLoading = false;
      }
    },
    selectColor(colorNum, colors){
      if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
      return "hsl(" + (colorNum * (360 / colors) % 360) + ",100%,50%)";
    },
    async barChartYearClick(evt){
      this.chartYearLoading = true;
      this.pieChartColors = [];

      this.selectedYear = this.chartYears[evt.index];

      var response = await this.$api.transaction.getSuspensionSummaryPieChart(this.$siteKey, "year", this.chartYears[evt.index], evt.dataIndex)

      for (var i = 0; i < Object.keys(response.data).length; i++){
        this.pieChartColors.push(this.selectColor(Math.floor(Math.random() * 10), 10))
      }
      this.pieChartYearLabels = Object.keys(response.data);
      this.pieChartYearData = Object.values(response.data);
      this.pieChartYearVisible = !this.pieChartYearVisible;
      this.chartYearLoading = false;
    },
    async barChartMonthClick(evt){
      this.pieChartColors = [];
      this.chartMonthLoading = true;

      var response = await this.$api.transaction.getSuspensionSummaryPieChart(this.$siteKey, "month", evt.index+1, evt.dataIndex, this.displayYear)

      for (var i = 0; i < Object.keys(response.data).length; i++){
        this.pieChartColors.push(this.selectColor(Math.floor(Math.random() * 10), 10))
      }
      this.pieChartMonthLabels = Object.keys(response.data);
      this.pieChartMonthData = Object.values(response.data);
      this.pieChartMonthVisible = !this.pieChartMonthVisible;
      this.chartMonthLoading = false;
    },
    async barChartWeekClick(evt){
      this.chartWeekLoading = true;
      this.pieChartColors = [];
      this.selectedWeek = moment(this.chartFourWeeks[evt.index][0]).format("DD/MM/YY") + " - " + moment(this.chartFourWeeks[evt.index][this.chartFourWeeks[0].length-1]).format("DD/MM/YY")
      
      var response = await this.$api.transaction.getSuspensionSummaryPieChart(this.$siteKey, "week", evt.index, evt.dataIndex)

      for (var i = 0; i < Object.keys(response.data).length; i++){
        this.pieChartColors.push(this.selectColor(Math.floor(Math.random() * 10), 10))
      }
      this.pieChartWeekLabels = Object.keys(response.data);
      this.pieChartWeekData = Object.values(response.data);
      this.pieChartWeekVisible = !this.pieChartWeekVisible;
      this.chartWeekLoading = false;
    },
    getFourWeeks() {
      var temp = [];

      for (var i = 0; i > -4; i--) {
        temp.push(getWeekRange(i));
      }
      temp.reverse();

      this.chartFourWeeks = temp;

      function getWeekRange(week = 0) {
        var weekStart = moment().startOf('isoWeek').add(week, "weeks");
        return [...Array(7)].map((_, i) => weekStart.clone().add(i, "day").format("YYYY-MM-DD"));
      }
    },
    setWeekDates() {
      var dates = [];

      this.chartFourWeeks.forEach((element) => {
        dates.push(
          moment(element[0]).format("DD/MM/YY") +
          "  -  " +
          moment(element[6]).format("DD/MM/YY")
        );
      });
      this.chartWeekDays = dates;
    },
    getMonths() {
      this.chartMonths = moment.monthsShort();
    },
    getYears() {
      var maxLength = 5;
      for (var y = 0; y < 6; y++){
        if (moment().year() - y >= 2023 && !this.chartYears.includes(moment().year()-y) && this.chartYears.length < maxLength)
        this.chartYears.push(moment().year()-y)
      }
      this.chartYears.reverse();
      for (var x = 0; x < 5; x++) { 
        if (!this.chartYears.includes(moment().year()+x)&&this.chartYears.length<maxLength){
          this.chartYears.push(moment().year()+x)
        }
      }
    },
  }
};
</script>
