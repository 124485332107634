<template>
    <v-container fluid>
        <v-dialog v-model="mainDialog" persistent max-width="600px">
            <v-card v-if="addEditCard">
                <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
                    {{formTitle}}
                    <v-icon large class="white--text ml-4">mdi-account</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="editedItem.name"
                                label="Name"
                            >
                                <v-icon slot="prepend">mdi-star</v-icon>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="editedItem.role"
                                label="Role"
                                :items="computeRoles"
                            >
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.replace(/([a-z])([A-Z])/g, '$1 $2') }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.replace(/([a-z])([A-Z])/g, '$1 $2') }}
                                </template>
                                <v-icon slot="prepend">mdi-star</v-icon>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="editedItem.email"
                                label="Email"
                                :rules="[
                                    () => !!editedItem.email || 'Field required',
                                    () => !!checkEmail || 'Not a valid email address'
                                ]"
                            >
                                <v-icon slot="prepend">mdi-star</v-icon>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text large @click="mainDialog = false">Cancel</v-btn>
                    <v-btn class="primary" large :disabled="!checkEmail" @click="confirmMember()">Save</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="deleteCard">
                <v-card-title class="mb-2 justify-start px-4 py-6 primary white--text text-h4 justify-center">
                    Delete Member
                    <v-icon large class="white--text ml-4">mdi-delete</v-icon>
                </v-card-title>
                <v-card-text>
                    <span>Are you sure you wish to delete this member?</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text large @click="mainDialog = false">Cancel</v-btn>
                    <v-btn class="primary" large @click="deleteMember(deleteItem.id)">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
            :items="membersList"
            :headers="membersHeaders"
            :search="search"
            class="elevation-1 px-6 py-6"
        >
            <template v-slot:[`item.role`]="{item}">
                {{ item.role.replace(/([a-z])([A-Z])/g, '$1 $2') }}
            </template>
            <template v-slot:top>
                <v-toolbar class="mb-4" flat>
                <v-toolbar-title class="text-h4">Members</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
    
                <v-btn color="primary" dark class="mb-2" large @click="reset(), openAddEdit()">Add Member</v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" color="primary"  @click="editMember(item)" medium>mdi-pencil</v-icon>
                <v-icon class="mr-2" color="red"  @click="openDelete(item)" medium>mdi-delete</v-icon>
              </template>
        </v-data-table>
    </v-container>
</template>
<script>

export default {
    // eslint-disable-next-line
    name: 'Members',
    data() {
        return {
            mainDialog: false,
            addEditCard: false,
            deleteCard: false,

            editedIndex: -1,
            editedItem: {
                name: "",
                email: "",
                role: "",
                siteKey: ""
            },
            deleteItem:null,

            search: '',
            membersList: [],
            membersHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Role', value: 'role' },
                { text: 'Action', cellClass: "py-2", value: 'actions', sortable: false, width:"100px", align:"center" }
            ],
        }
    },
    computed: {
        computeRoles(){
            var constructedList = [];
            constructedList.push("SuperUser")
            constructedList.push(this.$userRole)
            var itemToSearch = this.$userRole;
            for (var i = 0; i < Object.keys(this.$api.allowedPages).length; i++){
                if (Object.keys(this.$api.allowedPages)[i] == itemToSearch){
                    if(Object.keys(this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]]).includes("Child")){
                        constructedList = constructedList.concat(this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]].Child)
                        itemToSearch = this.$api.allowedPages[Object.keys(this.$api.allowedPages)[i]].Child
                    }
                }
            }
            return constructedList
        },
        formTitle () {
            return this.editedIndex === -1 ? 'New Member' : 'Edit Member'
        },
        checkEmail(){
            return (this.editedItem.email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) == null ? false : true
        },
    },
    async mounted(){
        await this.getMembers();
    },
    methods:{
        openAddEdit(){
            this.deleteCard = false;
            this.addEditCard = true;
            this.mainDialog = true;
        },

        openDelete(item){
            this.deleteItem = Object.assign({}, item)
            this.addEditCard = false;
            this.deleteCard = true;
            this.mainDialog = true;
        },      

        async getMembers(){
            await this.$api.member.getAll(this.$siteKey).then(response=>{
                this.membersList = response.data
            });
        },

        async confirmMember(){
            this.editedItem.siteKey = this.$siteKey;
            await this.$api.member.createEditMember(this.$siteKey, this.editedItem).then(()=>{(async () => await this.reset())();})
        },

        async editMember(item) {
            this.editedIndex = this.membersList.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.openAddEdit();
        },

        async deleteMember(id){
            await this.$api.member.deleteMember(this.$siteKey, id).then(()=>{(async () => await this.reset())();});
        },

        async reset() {
            this.editedIndex = -1;
            this.editedItem = {
                name: "",
                email: "",
                role: "",
                siteKey: "",
            }
            this.mainDialog = false;
            await this.getMembers();
        },
    }
}
</script>