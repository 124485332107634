<template>
    <v-container v-if="siteData">
        <v-card class="my-2">
            <v-card-title>Prisoner Noticeboard</v-card-title>
            <v-card-text>
                <v-textarea
                    v-model="siteData.prisonerNoticeboard"
                >
                </v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="siteData.prisonerNoticeboard = null">
                    Clear
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="saveNoticeboard('Prisoner')">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card class="my-2">
            <v-card-title>Staff Noticeboard</v-card-title>
            <v-card-text>
                <v-textarea
                v-model="siteData.staffNoticeboard"
                >
                </v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="siteData.staffNoticeboard = null">
                    Clear
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="saveNoticeboard('Staff')">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar
            v-if="snackbarVisible"
            v-model="snackbarVisible"
            :color="snackbarColor"
        >
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="snackbarVisible = false">
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Noticeboard',
    data() {
        return {
            siteData: null,
            snackbarVisible: false,
            snackbarColor: null,
            snackbarText: null,
        }
    },
    mounted(){
        // read both Noticeboards
        this.getAllNoticeboard();
    },
    methods: {
        async saveNoticeboard(userType){
            var response = await this.$api.site.uploadNoticeboard(this.$siteKey, userType, this.siteData)
            if (response.status == 200){
                this.snackbarColor = "green";
                this.snackbarText = `${userType} Noticeboard updated successfully`;
            }
            else{
                this.snackbarColor = "red";
                this.snackbarText = `${userType} Noticeboard failed to update`;
            }
            this.snackbarVisible = true;
        },
        async getAllNoticeboard(){
            var response = await this.$api.site.getAllNoticeboard(this.$siteKey);
            this.siteData = response.data;
        },
    }
}
</script>
