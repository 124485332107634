import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        build: {},
        simulator: {
            visible: false,
            digits: null,
            displayName: null
        }
    },
    mutations: {
        setBuild(state, build) {
            state.build = { ...build }
        },
        setSimulator(state, { digits, displayName }) {
            state.simulator.digits = digits
            state.simulator.displayName = displayName
            state.simulator.visible = true
        },
        showSimulator(state, visible) {
            state.simulator.visible = visible
        }
    }
})

export default store
