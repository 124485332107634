export const dataApiServer = {
    host: "api.cris.unify.fun",
    port: ``,
    ssl: true
}

export const pdfApiServer = {
  host: "pdf-file.unify.fun", //api.cris.unify.fun //pdf-file.unify.fun
  port: ``, //7204
  ssl: true
}
// Allowed pages in order of status, the highest tiered role must be on top. Followed by the next role and so on
export const AllowedPages = {
    "SuperUser":{"Child": "Administrator"},
    "Administrator":{"Child":"Reporter", "Allowed":["Staff", "Prisoners", "Settings", "Documents", "Noticeboard"]},
    "Reporter":{"Allowed":["Dashboard", "Members", "Transactions"]}
}

// wsHost is our connection for carring SIP over a WebSocket
// Used by the simulator, which connects normally to the (proxied)
// asterisk HTTP service - since different PIN systems can be
// obliged by different asterisk services, this value comes by default
// from the PrimaryHostname value in the build specification - the
// override setting allows this to be overriden for debug scenarios
// where HTTPS might not be enabled, for example. Partial overrides are
// supported - it is unlikely it will be appropriate to override the
// host value, only port and ssl
export const wsHostOverride = {
    //host: ,
    port: 8088,
    ssl: false
}

// stasisHost is our connection to the HTTP endpoint on the Lebedev
// stasis appliction - it is used in circumstances where we wish
// to leverage direct control of the stasis application and its
// associated asterisk instance which obliges a specific PIN system,
// for example requesting creation of a burner or barge phonem or
// connection to live status signalR websocket service. This value
// comes by default from the PrimaryHostname value in the build
// specification but can be overriden here for debug scenarios in
// which HTTPS might not be enabled, for example, or in which the
// stasis application runs on a different host to the asterisk
// instance. Partial overrides are supported.
export const stasisHostOverride = {
    host: "127.0.0.1",
    port: 4000,
    ssl: false
}
