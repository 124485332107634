<template>
  <v-app>
    <v-app-bar app clipped-left dense class="elevation-3" color="white">
            <v-app-bar-nav-icon class="mx-1" href="/"><v-icon>mdi-home</v-icon></v-app-bar-nav-icon>
            <img src="/unifylogo.svg" class="pa-0 mr-3" height="15" />
            <v-toolbar-title class="text-h6 pa-0 mr-3" >CRIS</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu bottom left offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-1" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense nav>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">
                                {{ this.$auth.userProfile.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item link @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $vuetify.theme.dark ? 'Light' : 'Dark' }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$auth.signOut()">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Sign Out</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        


    <Sidebar v-if="userRole && $siteKey" ref="Sidebar" :drawer="drawer" :userRole="userRole"/>
    
    <v-main class="pl-14 grey lighten-2">
      
      <div class="py-2" v-if='$siteKey == "serdov"'>
      <router-link class='my-auto mx-auto' :to="{name: 'Dashboard'}" v-if="userAuthorised">
        <v-img
            alt="Dovegate Inspire Logo"
            class="  py-6"
            contain
            src="./assets/SD_Inspire_Logo.png"
            height="80"
          /></router-link>
        </div>
      <router-view class="align-start mt-auto px-8" v-if="userAuthorised"></router-view> 
      <System v-else>

      </System>
    </v-main>
  </v-app>
</template>


<script>
import Sidebar from './components/Sidebar';
import System from './views/System'
import Vue from 'vue'
export default {
  name: 'App',
  components: {
    Sidebar,
    System
  },
  data() {
    return {
      drawer: true,
      uName: '',
      userRole: null,
      userAuthorised: true,
    }
  },
  async mounted(){
    await this.getUserRole();
  },
  async updated() {
    var name = this.$auth.user.profile.name.split('@')
    this.uName = name[0]
    await this.getUserRole();
  },
  methods:{
    async getUserRole(){
      if (!this.$auth.user || !this.$siteKey){
        setTimeout(this.getUserRole, 250)
        return;
      }
      var memberResponse = await this.$api.member.getAll(this.$siteKey)
      var user = memberResponse.data.find(element=>element.email.toLowerCase() == this.$auth.user.profile.name.toLowerCase())
      if (user){
          this.userRole = memberResponse.data.find(element=>element.email == this.$auth.user.profile.name).role
          Vue.prototype.$userRole = memberResponse.data.find(element=>element.email == this.$auth.user.profile.name).role
          this.userAuthorised = true;
      }
      else if (this.$auth.user.profile.role == "super"){
          this.userRole = "SuperUser"
          Vue.prototype.$userRole = "SuperUser"
          this.userAuthorised = true;
      }
      else{
          this.userAuthorised = false;
      }
      await this.$refs.Sidebar.getConstructedList();
    },
    signOut() {
        this.$auth.signOut()
    }
  }
};
</script>
