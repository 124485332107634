<template>
    <div>
        <div style="justify-content: center; align-items: center; display: flex; margin-top:20px">
            You have not been given permission to view data for this site. Please contact your system administrator.
          </div>
    </div>
</template>
<script>
    export default {
        // eslint-disable-next-line
        name: 'System',
    }
</script>