import axios from 'axios'

export default class apiV2Service {
    constructor() {
        this.controlService = axios.create()
    }

    useAuth(auth) {
        this.controlService.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${auth.accessToken}`
            return config
        })
    }
}
