<template>
  <v-container fluid>
      <v-row class="my-2">
        <v-col class="py-2 text-center d-inline-block" xs="12" sm="6">
          <v-card class="mb-4 py-0 px-0">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-file-pdf-box</v-icon>
              <div>Main Prison File Upload</div>
            </v-card-title>
            <div class="px-6 py-6 flex-grow-1">
              <v-file-input
                accept="application/pdf"
                counter
                show-size
                truncate-length="15"
                :label="guideFileName"
                v-model="quickGuideFile"
              ></v-file-input>
              <v-text-field
                prepend-icon="mdi-format-title"
                v-model="quickGuideTitle"
                label="Quick Guide Title"
              ></v-text-field>
              <v-btn class="primary flex justify-end mr-2" :disabled="quickGuideFile == null" @click="importImage(quickGuideFile)">Preview</v-btn>
              <v-btn class="primary flex justify-end ml-2" :disabled="quickGuideFile == null" @click="uploadGuide(quickGuideFile)">Save</v-btn>

              <v-file-input
                accept="application/pdf"
                counter
                show-size
                truncate-length="15"
                :label="bookletFileName"
                v-model="bookletFile"
              ></v-file-input>
              <v-text-field
                prepend-icon="mdi-format-title"
                v-model="bookletTitle"
                label="Booklet Title"
              ></v-text-field>
              <v-btn class="primary flex justify-end mr-2" :disabled="bookletFile == null" @click="importImage(bookletFile)">Preview</v-btn>
              <v-btn class="primary flex justify-end ml-2" :disabled="bookletFile == null" @click="uploadBooklet(bookletFile)">Save</v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col v-if="imageData" class="py-2 text-center d-inline-block" xs="12" sm="6">
          <v-card class="mb-4 py-0 px-0">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-file-eye</v-icon>
              <div>File Preview</div>
            </v-card-title>
            <div class="px-6 py-6">
              <v-row style="justify-content: center; align-items: center">
                <v-btn @click="currentPage--" :disabled="currentPage==1">Back</v-btn>
                <v-spacer></v-spacer>
                {{currentPage}} / {{pageCount}}
                <v-spacer></v-spacer>
                <v-btn @click="currentPage++" :disabled="currentPage==pageCount">Next</v-btn>
              </v-row>
              <div class="mt-4">
                <pdf 
                  :source="imageData"
                  :page="currentPage"
                  @internal-link-clicked="currentPage = $event"
                ></pdf>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="my-2" v-if="$siteKey == 'serdov'">
        <v-col class="py-2 text-center d-inline-block" xs="12" sm="6">
          <v-card class="mb-4 py-0 px-0">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-file-pdf-box</v-icon>
              <div>TC File Upload</div>
            </v-card-title>
            <div class="px-6 py-6 flex-grow-1">
              <v-file-input
                accept="application/pdf"
                counter
                show-size
                truncate-length="15"
                :label="tcGuideFileName"
                v-model="tcQuickGuideFile"
              ></v-file-input>
              <v-text-field
                prepend-icon="mdi-format-title"
                v-model="tcQuickGuideTitle"
                label="Quick Guide Title"
              ></v-text-field>
              <v-btn class="primary flex justify-end mr-2" :disabled="tcQuickGuideFile == null" @click="importImage(tcQuickGuideFile)">Preview</v-btn>
              <v-btn class="primary flex justify-end ml-2" :disabled="tcQuickGuideFile == null" @click="uploadTcGuide(tcQuickGuideFile)">Save</v-btn>

              <v-file-input
                accept="application/pdf"
                counter
                show-size
                truncate-length="15"
                :label="tcBookletFileName"
                v-model="tcBookletFile"
              ></v-file-input>
              <v-text-field
                prepend-icon="mdi-format-title"
                v-model="tcBookletTitle"
                label="Booklet Title"
              ></v-text-field>
              <v-btn class="primary flex justify-end mr-2" :disabled="tcBookletFile == null" @click="importImage(tcBookletFile)">Preview</v-btn>
              <v-btn class="primary flex justify-end ml-2" :disabled="tcBookletFile == null" @click="uploadTcBooklet(tcBookletFile)">Save</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="my-2">
        <v-col class="py-2 text-center d-inline-block" xs="12" sm="6">
          <v-card class="mb-4 py-0 px-0">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-file-pdf-box</v-icon>
              <div>Staff File Upload</div>
            </v-card-title>
            <div class="px-6 py-6 flex-grow-1">
              <v-file-input
                accept="application/pdf"
                counter
                show-size
                truncate-length="15"
                :label="staffGuideFileName"
                v-model="staffGuideFile"
              ></v-file-input>
              <v-text-field
                prepend-icon="mdi-format-title"
                v-model="staffGuideTitle"
                label="Quick Guide Title"
              ></v-text-field>
              <v-btn class="primary flex justify-end mr-2" :disabled="staffGuideFile == null" @click="importImage(staffGuideFile)">Preview</v-btn>
              <v-btn class="primary flex justify-end ml-2" :disabled="staffGuideFile == null" @click="uploadStaffGuide(staffGuideFile)">Save</v-btn>

              <v-file-input
                accept="application/pdf"
                counter
                show-size
                truncate-length="15"
                :label="staffBookletFileName"
                v-model="staffBookletFile"
              ></v-file-input>
              <v-text-field
                prepend-icon="mdi-format-title"
                v-model="staffBookletTitle"
                label="Booklet Title"
              ></v-text-field>
              <v-btn class="primary flex justify-end mr-2" :disabled="staffBookletFile == null" @click="importImage(staffBookletFile)">Preview</v-btn>
              <v-btn class="primary flex justify-end ml-2" :disabled="staffBookletFile == null" @click="uploadStaffBooklet(staffBookletFile)">Save</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
  // eslint-disable-next-line
  name: 'Settings',
  components: {
    pdf
  },
  data() {
    return {
        bookletFile:null,
        bookletTitle: 'Main Prison Booklet',
        tcBookletFile: null,
        tcBookletTitle: 'TC Booklet',
        quickGuideFile:null,
        quickGuideTitle: 'Main Prison Quick Guide',
        tcQuickGuideFile: null,
        tcQuickGuideTitle: 'TC Quick Guide',
        imagePath: null,
        imageData: null,
        guideFileName: "",
        tcGuideFileName: "",
        bookletFileName: "",
        tcBookletFileName: "",
        staffGuideFileName: "",
        staffBookletFile: null,
        staffGuideFile: null,
        staffBookletTitle: "Staff Booklet",
        staffGuideTitle: "Staff Guide",
        staffBookletFileName: "",


        pageCount: 0,
        currentPage: 1,
    }
  },
  
  async mounted() {
    await this.getSite()
  },
  methods: {
    base64ToArrayBuffer(base64) {
      var binaryString = atob(base64);
      var bytes = new Uint8Array(binaryString.length);
      for (var i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    },

    async getSite() {
      try {
        var response = await this.$api.site.getOne(this.$siteKey)
        if (response.data.booklet){
          this.bookletFile = new Blob([this.base64ToArrayBuffer(response.data.booklet)], { type: 'application/pdf' });
          this.bookletFile.name = this.bookletFileName = response.data.bookletPath
          
        }
        if (response.data.guide){
          this.quickGuideFile = new Blob([this.base64ToArrayBuffer(response.data.guide)], { type: 'application/pdf' });
          this.quickGuideFile.name = this.guideFileName = response.data.guidePath
        }
        if (response.data.tcBooklet){
          this.tcBookletFile = new Blob([this.base64ToArrayBuffer(response.data.tcBooklet)], { type: 'application/pdf' });
          this.tcBookletFile.name = this.tcBookletFileName = response.data.tcBookletPath
          
        }
        if (response.data.tcGuide){
          this.tcQuickGuideFile = new Blob([this.base64ToArrayBuffer(response.data.tcGuide)], { type: 'application/pdf' });
          this.tcQuickGuideFile.name = this.tcGuideFileName = response.data.tcGuidePath
        }
        if (response.data.staffBooklet){
          this.staffBookletFile = new Blob([this.base64ToArrayBuffer(response.data.staffBooklet)], { type: 'application/pdf' });
          this.staffBookletFile.name = this.staffBookletFileName = response.data.staffBookletPath
        }
        if (response.data.staffGuide){
          this.staffGuideFile = new Blob([this.base64ToArrayBuffer(response.data.staffGuide)], { type: 'application/pdf' });
          this.staffGuideFile.name = this.staffGuideFileName = response.data.staffGuidePath
        }

        this.snackbarText = 'Site loaded'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to load site'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    importImage(file) {
      this.currentPage = 1;
      this.imageData = URL.createObjectURL(file);
    },
    async uploadGuide(file) {
      let formData = new FormData();
      formData.append('guide',file);
      try {
        await this.$api.site.addGuide(this.$siteKey, formData)
        this.snackbarText = 'Guide updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update guide'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    async uploadBooklet(file) {
      let formData = new FormData();
      formData.append('booklet',file);
      try {
        await this.$api.site.addBooklet(this.$siteKey, formData)
        this.snackbarText = 'Booklet updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update booklet'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    async uploadTcGuide(file) {
      let formData = new FormData();
      formData.append('guide',file);
      try {
        await this.$api.site.addTcGuide(this.$siteKey, formData)
        this.snackbarText = 'TC Guide updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update guide'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    async uploadTcBooklet(file) {
      let formData = new FormData();
      formData.append('booklet',file);
      try {
        await this.$api.site.addTcBooklet(this.$siteKey, formData)
        this.snackbarText = 'TC Booklet updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update booklet'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    async uploadStaffGuide(file){
      let formData = new FormData();
      formData.append('staffGuide',file);
      try {
        await this.$api.site.addStaffGuide(this.$siteKey, formData)
        this.snackbarText = 'Staff Guide updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update Staff Guide'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
    async uploadStaffBooklet(file){
      let formData = new FormData();
      formData.append('staffBooklet',file);
      try {
        await this.$api.site.addStaffBooklet(this.$siteKey, formData)
        this.snackbarText = 'Staff Booklet updated'
        this.snackbarColor = 'primary'
        this.snackbarVisible = true
      } catch (error) {
        this.snackbarText = 'Failed to update Staff Booklet'
        this.snackbarColor = 'red'
        this.snackbarVisible = true
        console.log(error)
      }
    },
  }
}
</script>
